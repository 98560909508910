import {Injectable} from '@angular/core';
import {StorageUtilsService} from "@core/utils/storage.utils.service";
import {UserService} from "../../layout/common/user/user.service";

import Echo from "laravel-echo";
import Pusher from "pusher-js";
import {environment} from "@environments/environment";
import {AuthService} from "@core/auth/auth.service";
import {BehaviorSubject} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class PusherService {

  laravelEcho: any = null;

  _paymentEvent: BehaviorSubject<any | null> = new BehaviorSubject(null);


  /**
   * Constructor
   */
  constructor(private _storage: StorageUtilsService,
              private _userService: UserService,
              private _authService: AuthService) {
  }

  subscribePusher() {
    (window as any)['Pusher'] = Pusher;
    this.laravelEcho = new Echo({
      broadcaster: 'pusher',
      key: environment.push.key,
      disableStats: true,
      encrypted: true,
      cluster: 'mt1',
      forceTLS: false,
      enabledTransports: ['ws', 'wss']
    });
    // auth: {
    //   headers: {
    //     Authorization: 'Bearer ' + this._authService.accessToken
    //   },
    // },

    // const user = this._userService.user;
    // перепідписуваться якщо є у збережених ці id, у localstorage зберігать один день.
    // public - якась доп інфа по іграх
    // payment. _ paymentID -> коли виконав запит pay() -> прийде пеймент респонс
    // order. _ orderID -> коли отривав ордер на сторінці деталів оплати -> прийде телеграм посилання -> також може приходить нова гра, яку потрібно відобразить у модалці
    // this.publicSubscribe();

    this.checkSavedId();
  }

  // publicSubscribe() {
  //   if (!this.laravelEcho) {
  //     return;
  //   }
  //   this.laravelEcho.channel(`public`)
  //     .listen('PublicBroadcastingEvent', (e: any) => {
  //       if (e?.message?.type?.length) {
  //         if (e.message.type === 'newPrices') {
  //
  //
  //         }
  //       }
  //     });
  // }

  paymentSubscribe(id: string) {
    if (!this.laravelEcho) {
      return;
    }
    this.saveId(id, 's_p_id');
    this.laravelEcho.channel(`payment.${id}`)
      .listen('OrderBroadcastingEvent', (e: any) => {
        if (e?.message?.type?.length) {
          if (e.message.type === 'orderUpdate' || e.message.type === 'orderItemUpdate' ||
            e.message.type === 'top-up' || e.message.type === 'charge') {
            this._paymentEvent.next(e);
          }
        }
      });

    // orderUpdate - оплата успішна чи ні - перехід на success
    // orderItemUpdate - дійде телеграм ссилка
    // newPick
  }

  saveId(id: string, key: string) {
    if (!this._storage.get(key)) {
      const date: Date = new Date();
      date.setDate(date.getDate() + 1);
      this._storage.set(key, id, date);
    }
  }

  checkSavedId() {
      this.paymentSubscribe('pa_vlJSizBCwKJf463B');
    // const payId = this._storage.get('s_p_id')
    // if (payId) {
    //   this.paymentSubscribe(payId);
    // }
  }

}




import {Inject, Injectable} from '@angular/core';
import {DOCUMENT} from '@angular/common';
import {NavigationEnd, Router} from '@angular/router';
import {filter, take} from 'rxjs/operators';

@Injectable()
export class SplashScreenService {
  /**
   * Constructor
   *
   * @param {Router} _router
   */
  constructor(
    private _router: Router
  ) {
    // Initialize
    this._init();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Private methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Initialize
   *
   * @private
   */
  private _init(): void {
    // Hide it on the first NavigationEnd event
    this._router.events
      .pipe(
        filter(event => event instanceof NavigationEnd)
      )
      .subscribe(() => {

        // Hide the splash screen
        this.hide();
      });
  }

  /**
   * Hide the splash screen
   */
  hide(): void {
    const p = document.getElementById('preloader');
    if (p) {
      p.classList.add('hide');
      setTimeout(() => {
        p.style.display = 'none';
      }, 500)
    }
  }
}

import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'addPlus',
  pure: false
})
export class AddPlusPipe implements PipeTransform {

  constructor() {
  }

  transform(value: any): any {
    if(value === null){
      return '';
    }
    return value > 0 ? '+' + value : value
  }
}

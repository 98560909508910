import {Injectable} from '@angular/core';

import firebase from 'firebase/compat/app';
import 'firebase/compat/messaging';

import {PublicService} from "../../modules/public/public.service";
import {environment} from "@environments/environment";
import {UserService} from "../../layout/common/user/user.service";
import {AuthService} from "@core/auth/auth.service";

@Injectable({
  providedIn: 'root'
})
export class FirebaseService {
  messaging: any;

  /**
   * Constructor
   */
  constructor(private _userService: UserService,
              private _authService: AuthService) {

  }

  init() {
    if (typeof window !== 'undefined' && 'serviceWorker' in navigator) {
      firebase.initializeApp(environment.firebaseConfig);
      this.messaging = firebase.messaging();
      this.requestPermission();
    }
  }

  requestPermission() {
    if (Notification.permission !== 'granted') {
      Notification.requestPermission().then((_) => {
        this.getToken();
      });
    }else{
      this.getToken();
    }
  }

  getToken(){
    if (this.messaging) {
      this.messaging.getToken()
        .then((currentToken: string) => {
          if (currentToken) {
            this.subscribeTokenToTopic(currentToken);
            this.subscribeTokenToUserTopic(currentToken);
            this.listen();
          } else {
            // console.log('No registration token available. Request permission to generate one.');
          }
        });
    }
  }

  subscribeTokenToTopic(token: string) {
    this._userService.sendTokenFirebase({fcm_token: token}).subscribe();
  }

  subscribeTokenToUserTopic(token: string) {
    this._userService.user$.subscribe(res => {
      if (this._authService.hasAccessToken && res?.id) {
        this._userService.sendTokenFirebaseUser({fcm_token: token}).subscribe();
      }
    })
  }

  listen() {
    if (this.messaging) {
      this.messaging.onMessage((incomingMessage: any) => {
        new Notification(incomingMessage.notification.title, incomingMessage.notification);
      })
    }
  }

}


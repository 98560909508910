<div class="pay-form">

  <div class="single-box text-center">
    <h6>Checkout</h6>
    <div class="radio-area subscribe d-flex justify-content-center">
      <label class="single-radio">
        {{ p.name }}
        <span class="amount" [ngClass]="{'line-through': payInfo.newTotal?.length}">
          {{ payInfo.total | currency }}
        </span>
        <span class="amount" *ngIf="payInfo.newTotal?.length">
          {{ payInfo.newTotal | currency }}
        </span>
        <input type="radio" name="tipsSubs" class="checked">
        <div class="checkmark"></div>
      </label>
    </div>

    <div class="main">
      <ng-container *ngIf="formConfig.isMethod && form?.get('user')">
        <div class="transition form-area" [ngClass]="{'open': formConfig.isUser}">
          <form class="form-area" [formGroup]="form">
            <h6>Fill out the form to get access to the package</h6>
            <div class="single-input col-12" formGroupName="user">
              <!--              <label for="email">Your email address</label>-->
              <input type="text" id="email" placeholder="Your email address"
                     formControlName="email">
              <span class="error"
                    *ngIf="form?.get('user')?.get('email')?.touched && form?.get('user')?.get('email')?.invalid">
                      {{ _formUtilsService.getErrorMessage(form?.get('user'), 'email') }}
                    </span>
            </div>
          </form>
        </div>
      </ng-container>

      <div class="loader transition" [ngClass]="{'open': formConfig.isLoading}"></div>

      <div class="methods transition" [ngClass]="{'open': formConfig.isMethod}">
        <!--        <h6>Payment method</h6>-->
        <div class="methods transition open">
          <div class="radio-area">
            <ng-container *ngFor="let m of methods">
              <label class="single-radio justify-content-between" (click)="selectMethod(m)">
                <input type="radio"
                       [ngClass]="{'checked': m.id === selectedMethod?.id}"
                       name="tipsPay">
                <div class="checkmark"></div>
                <span class="title-item">{{ m.label }}</span>
                <span class="d-flex align-items-center">
                  <img class="transition with-background"
                       src="assets/icons/google-pay.png" alt="google pay" width="52" height="21"
                       [ngClass]="{'show': (m.asyncGooglePay | async)}">
                  <img class="transition with-background"
                       src="assets/icons/apple-pay.png" alt="google pay" width="52" height="21"
                       [ngClass]="{'show': (m.asyncApplePay | async)}">
                  <img class="transition" [src]="m.icon" alt="icon" width="52" height="21"
                       [ngClass]="{'show': m.icon?.length}"></span>
              </label>
            </ng-container>
          </div>
        </div>

        <div class="coupon-form transition" [ngClass]="{'open': coupon.show}">
          <form class="form-area" [formGroup]="coupon.group" (submit)="saveCoupon()">
            <h6>Enter a Discount Code</h6>
            <div class="single-input col-12">
              <input type="text" placeholder="Discount Code"
                     formControlName="discount">
              <span class="error"
                    *ngIf="coupon.group.get('discount')?.touched && coupon.group.get('discount')?.invalid">
                      {{ _formUtilsService.getErrorMessage(coupon.group, 'discount') }}
              </span>
            </div>
            <div class="flex">
              <div>
                <a class="link" (click)="coupon.show = false">Hide Discount</a>
              </div>
              <button class="cmn-btn" type="submit"
                      [ngClass]="{'is-process': coupon.isLoading}"
                      [disabled]="coupon.isLoading">
                <span>Apply Discount Code</span>
              </button>
            </div>
          </form>
        </div>

        <div class="w-100 transition" *ngIf="!coupon.show">
          <a class="link" (click)="toggleCoupon()">Have a Discount Code?</a>

<!--          I agree to the Terms and Conditions(посилання), Privacy Policy(посилання), and confirm my payment.-->
        </div>
      </div>

      <div class="stripe transition" [ngClass]="{'open': formConfig.isStripe}">

        <ng-container *ngIf="localData?.preventSelectType === 'stripePaymentButtons'">
          <p>
            To continue - click the button below
          </p>
        </ng-container>

        <div id="express-checkout-element"></div>

        <div id="payment-element"></div>

      </div>

      <div class="cryptomus transition" [ngClass]="{'open': formConfig.isCryptomus}">
        <iframe [src]="cryptomusUrl | safe : 'resourceUrl'"></iframe>
      </div>

      <div class="status transition" [ngClass]="{
    'open': formConfig.isStatus,
    'pending': message.status === 'pending',
    'failed': message.status === 'failed' ||
    message.status === 'forceClose' ||
    message.status === 'alreadyPurchasedError' ||
    allAlreadyPayed}">

        <ng-container *ngIf="message.status === 'alreadyPurchasedError'">
          <h2>
            {{ 'Such package(s) have already been purchased by you.' }}
          </h2>
        </ng-container>

        <ng-container *ngIf="message.status === 'forceClose' || message.status === 'failed'">
          <p>
            {{ message.text }}
          </p>
        </ng-container>

      </div>

    </div>

    <ng-container *ngIf="!formConfig.isCryptomus &&
    (!formConfig.isStripe || (formConfig.isStripe && localData?.preventSelectType !== 'stripePaymentButtons'))">

      <div class="btn-area transition" [ngClass]="{'hide': coupon.show}">
        <div class="buttons transition" *ngIf="!localData?.buttonConfig && formConfig.isMethod">
          <button class="cmn-btn google transition"
                  [ngClass]="{'show': (localData?.buttonConfig?.asyncGooglePay | async)}"
                  (click)="payWithStripePaymentButtons()">
          <span>
            <img ngSrc="assets/icons/google-pay.png" alt="google pay" width="52" height="18">
          </span>
          </button>
          <button class="cmn-btn google transition"
                  [ngClass]="{'show': (localData?.buttonConfig?.asyncApplePay | async)}"
                  (click)="payWithStripePaymentButtons()">
                    <span>
                      <img ngSrc="assets/icons/apple-pay.png" alt="apple pay" width="52" height="18">
                    </span>
          </button>
        </div>
        <button (click)="send()" class="cmn-btn"
                [disabled]="formConfig.isLoading"
                [ngClass]="{'is-process': formConfig.isLoading}">
          <span>
            <ng-container
              *ngIf="message.status === 'forceClose' || message.status === 'failed' || allAlreadyPayed; else def">
              {{ message.status === 'forceClose' ? 'Force Close' : 'Close' }}
            </ng-container>
            <ng-template #def>
              Buy Now
            </ng-template>
            </span>
        </button>
      </div>
    </ng-container>
  </div>

</div>

import {Injectable} from '@angular/core';
import {Title} from '@angular/platform-browser';

import {CookieService} from 'ngx-cookie-service';
import {environment} from "@environments/environment";
import {COMMON} from "@shared/contstants/common.const";

@Injectable({
  providedIn: 'root'
})
export class StorageUtilsService {

  /**
   * Constructor
   */
  constructor(private _cookieService: CookieService,
              private _titleService: Title) {

  }

  get isClient(): boolean {
    return !this.isBackend;
  }

  get isTgPage(): boolean {
    return !!window.location.href.includes('tg');
  }
  //
  // hasPatch(path: string): boolean{
  //     return !!window.location.href.includes(path)
  // }

  get isBackend(): boolean {
    return window.location.href.includes(COMMON.SITE_SECTION_BACKEND);
  }

  urlDependingValue(): string | null {
    if (this.isBackend) {
      return this.urlWithBackend();
    }
    return this.urlPublic();
  }

  urlEmpty(): string {
    return environment.apiUrl + '/api';
  }

  urlPublic(): string {
    return environment.apiUrl + '/api/public';
  }

  urlWithProfile(): string {
    return environment.apiUrl + '/api/profile';
  }

  urlWithBackend(): string {
    return environment.apiUrl + '/api/backend';
  }

  // urlWithControl(): string {
  //     return environment.apiUrl + '/api/control';
  // }

  // urlSection(): string {
  //     return this.isControl ? this.urlWithControl() : this.isBackend ? this.urlWithBackend() : this.urlWithProfile()
  // }

  // urlWithSite(): string {
  //     return `${this.urlSection()}/sites/${this.get('siteId')}`;
  // }

  clear(): void {
    this._cookieService.deleteAll();
  }

  check(field: string): any {
    return this._cookieService.check(COMMON.COOKIE_KEY + field);
  }

  set(field: string, data: any, expires: any = undefined): void {
    this._cookieService.set(COMMON.COOKIE_KEY + field, JSON.stringify(data), expires, '/');
  }

  remove(field: string): void {
    this._cookieService.delete(COMMON.COOKIE_KEY + field, '/');
  }

  get(field: string): any {
    if (this._cookieService.get(COMMON.COOKIE_KEY + field)) {
      return JSON.parse(this._cookieService.get(COMMON.COOKIE_KEY + field));
    }
    return null;
  }

  // CONFIG SITE

  setConfig(data: any): void {
    sessionStorage['config'] = JSON.stringify(data);
    this.checkConfig();
  }

  checkConfig(): void {
    const config = this.config;
    if (config && config.name) {
      environment.currentSiteName = config.name;
      this._titleService.setTitle(config.name);
    }
  }

  resetConfig(): void {
    delete sessionStorage['config'];
  }

  get config(): any {
    if (sessionStorage['config']) {
      return JSON.parse(sessionStorage['config']);
    }
  }

  get isXs(): boolean {
    const width = document?.documentElement?.clientWidth || window.screen.width;
    return width < 799;
  }

  get isLg(): boolean {
    const width = document?.documentElement?.clientWidth || window.screen.width;
    return width < 992;
  }

  loadJs(src: string, id: string, path: string) {
    return new Promise((resolve) => {
      if (document.getElementById(id) && (window as any)[path]) {
        return resolve(true)
      }
      const script = document.createElement('script');
      script.id = id;
      script.src = src;
      script.onload = (_) => {
        resolve(true)
      }
      document.head.append(script);
    });
  }


}


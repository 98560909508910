import {ChangeDetectorRef, Component, Input, OnDestroy, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {FormUtilsService} from "@core/utils/form-utils.service";
import {Subject, takeUntil} from "rxjs";
import {AuthService} from "@core/auth/auth.service";
import {NotificationUtilsService} from "@core/utils/notification-utils.service";
import {StorageUtilsService} from "@core/utils/storage.utils.service";

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
})
export class LoginComponent implements OnInit, OnDestroy {

  @Input() dialogRef: any = null;

  form: FormGroup = new FormGroup<any>({});
  isLoading: boolean = false;
  // faceBook: any = {
  //   loading: false
  // };

  listener: any = null;
  private _unsubscribeAll = new Subject();

  constructor(private fb: FormBuilder,
              public _formUtilsService: FormUtilsService,
              private _cdr: ChangeDetectorRef,
              private _storageUtilsService: StorageUtilsService,
              private _notificationUtilsService: NotificationUtilsService,
              public _authService: AuthService) {


  }

  ngOnInit() {
    this.initApple();
    this._authService.renderGoogleButton();
    this.subscribeGoogleEvent();
    // this._authService.initFacebook(this.subscribeFbEvent.bind(this));
    this._authService._isLogin = true;
    this.form = this.fb.group({
      username: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required, Validators.minLength(6)]]
    });
  }

  cdr(v: boolean) {
    this.isLoading = v;
    this._cdr.detectChanges();
    this._cdr.markForCheck();
  }

  ngOnDestroy() {
    this._authService._isLogin = false;
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.unsubscribe();
    if (this.listener) {
      document.removeEventListener('AppleIDSignInOnSuccess', this.listener, false)
    }
  }

  subscribeGoogleEvent() {
    this._authService._authGoogle.subscribe(res => {
      if (res.success) {
        this.isLoading = false;
        this.dialogRef.close();
      } else {
        this._notificationUtilsService.showActionNotification(
          'An error occurred with google during authorization, please try again later', 0);
      }
    })
  }

  changeType(e: any, type: string) {
    e.stopPropagation();
    e.preventDefault();
    this._authService.authType = type;
  }


  submit() {
    if (this.form.invalid) {
      this._formUtilsService.validation(this.form);
      return;
    }
    const data = this.form.getRawValue();
    this.form.disable();
    this.isLoading = true;

    const request = this._authService.signIn(data);
    this.loginRequest(request);
  }

  loginRequest(request: any) {
    this.form.disable();
    this.isLoading = true;
    request.subscribe(() => {
      this.isLoading = false;
      this.dialogRef.close();
    }, (error: any) => {
      if (error.isAlreadyLogin) {
        this.isLoading = false;
        this.dialogRef.close();
        return;
      }
      this.isLoading = false;
      this.form.enable();
      const message = this._formUtilsService.prepareServerError(error, this.form, this._cdr);
      this._notificationUtilsService.showActionNotification(message?.message, message?.type);
    });
  }

  get isMac() {
    var isMacLike = /(Mac|iPhone|iPod|iPad)/i.test(navigator.platform);
    var isIOS = /(iPhone|iPod|iPad)/i.test(navigator.platform);
    return isIOS || isMacLike;
  }

  initApple() {
    this.listener = (event: any) => {
      const code = event.detail?.authorization?.code || '';
      if (!code) {
        this._notificationUtilsService.showActionNotification('An error occurred during authorization, please try again later', 0)
        return;
      }
      this.loginBySocial({"code":code}, 'apple');
    }
    this._authService.initApple(this.listener);
  }

  loginBySocial(data: any, type: string) {
    const name = type[0].toUpperCase() + type.slice(1, type.length)
    this._authService.loginBySocial(data, type).subscribe(_ => {
      this.isLoading = false;
      this.dialogRef.close();
    }, err => {
      this.isLoading = false;
      const text = err.error || 'Unable to log in via ' + name;
      this._notificationUtilsService.showActionNotification(text, 0);
    })
  }

  // subscribeFbEvent() {
  //   this._authService.loginFb(null, false, this.dialogRef, this.cdr.bind(this));
  // }

}

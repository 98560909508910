import { Component } from '@angular/core';
import { ActivatedRoute} from '@angular/router';
import { Layout } from 'app/layout/layout.types';

@Component({
    selector     : 'layout',
    templateUrl  : './layout.component.html',
    styleUrls    : ['./layout.component.scss'],
})
export class LayoutComponent{

    layout: Layout = 'empty';

    /**
     * Constructor
     *
     * @param _route
     */
    constructor(private _route: ActivatedRoute) {
      this.layout = this._route.snapshot.data['layout'] || 'empty';
    }

}

import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class TracardiService {

  /**
   * Constructor
   */
  constructor() {
  }

  track(type: string, data: any) {
    const t = (window as any).tracker || null;
    if (t) {
      t.track(type, data);
    }
  }

}


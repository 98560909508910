import {NgModule} from '@angular/core';
import {CheckScrollDirective} from "./check-scroll.directive";
import {AnimateDirective} from "@shared/directive/animate.directive";
import {HighlightDirective} from "@shared/directive/highlight.directive";


@NgModule({
  declarations: [
    CheckScrollDirective,
    AnimateDirective,
    HighlightDirective
  ],
  exports: [
    CheckScrollDirective,
    AnimateDirective,
    HighlightDirective
  ]
})
export class DirectiveModule {
}

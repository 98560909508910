import {Component, Inject, OnInit, ViewEncapsulation} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {AuthService} from "@core/auth/auth.service";

@Component({
  selector: 'app-action-notification',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AuthComponent implements OnInit {

  type: string = 'logIn';
  hideLogin: boolean = false;

  constructor(public dialogRef: MatDialogRef<AuthComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any,
              private _authService: AuthService) {

  }

  /**
   * On init
   */
  ngOnInit(): void {
    this.type = this.data?.type?.length ? this.data.type : 'logIn';
    this.hideLogin = this.data?.hideLogin || false;

    // this.type = 'newPassword';

    // this.checkLoadConfig();
    this._authService.authType$.subscribe(res => {
      if (res?.length) {
        this.type = res;
        // this.checkLoadConfig();
      }
    })

  }

  // checkLoadConfig() {
  //   if (this.type === 'signUp' || this.type === 'logIn') {
      // if (!this._authService.config?.length && !this.configLoad) {
      //   this.configLoad = true;
      //   this._authService.loadConfig().subscribe(_ => this.configLoad = false);
      // }
  //   }
  // }


  changeType(type?: string) {
    if (!type) {
      return;
    }
    this.type = type;
  }

  public close(): void {
    this.dialogRef.close();
  }
}

import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {ExtraOptions, RouterModule, UrlSerializer} from '@angular/router';
import {CoreModule} from 'app/core/core.module';
import {LayoutModule} from 'app/layout/layout.module';
import {AppComponent} from 'app/app.component';
import {WINDOW_PROVIDERS} from "@core/utils/window.provider";
import {CommonService} from "@shared/services/common.service";
import {environment} from "@environments/environment";
import {MatomoModule} from 'ngx-matomo';
import {appRoutes} from 'app/app.routing';
import {AuthModule} from "./modules/auth/auth.module";
import {SharedModule} from "@shared/shared.module";
import {GravatarModule} from "ngx-gravatar";
import {LowerCaseUrlSerializer} from "@core/utils/lower-case-url-serializer";

const routerConfig: ExtraOptions = {
  scrollPositionRestoration: 'enabled',
};


@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    // Permissions
    RouterModule.forRoot(appRoutes, routerConfig),

    // Core
    CoreModule,

    // Layout
    LayoutModule,

    MatomoModule.forRoot({
      scriptUrl: '//zenanalytics.net/matomo.js',
      trackers: [
        {
          trackerUrl: '//zenanalytics.net/matomo.php',
          siteId: environment.analytics.id
        }
      ],
    }),
    AuthModule,
    GravatarModule.forRoot({}),
    SharedModule
  ],
  providers: [
    WINDOW_PROVIDERS,
    CommonService,
    {
      provide: UrlSerializer,
      useClass: LowerCaseUrlSerializer
    }
  ],
  bootstrap: [
    AppComponent
  ]
})
export class AppModule {
}

import {Injectable} from '@angular/core';
import {MatomoTracker} from "ngx-matomo";

@Injectable({
  providedIn: 'root'
})
export class MatomoService {

  /**
   * Constructor
   */
  constructor(private matomoTracker: MatomoTracker) {
  }

  setEcommerceView(productSKU: string, productName: string, productCategory: string, price: number) {
    console.log('setEcommerceView');
    console.log(productSKU + ' - ' + productName + ' - ' + productCategory + ' - ' + price);
    this.matomoTracker.setEcommerceView(productSKU, productName, productCategory, price);
    this.trackPageView();
  }

  addEcommerceItem(productSKU: string, productName: string, productCategory: string, price: number) {
    console.log('addEcommerceItem');
    console.log(productSKU + ' - ' + productName + ' - ' + productCategory + ' - ' + price);
    this.matomoTracker.addEcommerceItem(productSKU, productName, productCategory, price);
    this.trackPageView();
  }

  trackEcommerceCartUpdate(total: number) {
    // console.log('trackEcommerceCartUpdate - ' + total);
    this.matomoTracker.trackEcommerceCartUpdate(total);
    this.trackPageView();
  }

  removeEcommerceItem(id: string) {
    // console.log('removeEcommerceItem');
    this.matomoTracker.removeEcommerceItem(id);
    this.trackPageView();
  }

  trackEcommerceOrder(orderId: string, grandTotal: number,) {
    // console.log('trackEcommerceOrder');
    // console.log(orderId + ' - ' + grandTotal);
    this.matomoTracker.trackEcommerceOrder(orderId, grandTotal);
    this.trackPageView();
  }

  clearEcommerceCart() {
    // console.log('clearEcommerceCart');
    this.matomoTracker.clearEcommerceCart();
    this.trackPageView();
  }

  trackPageView() {
    this.matomoTracker.trackPageView();
  }

  setUserId(id: string) {
    this.matomoTracker.setUserId(id);
    this.trackPageView();
  }

  // getEcommerceItems() {
  //   this.matomoTracker.getEcommerceItems().then(res => {
  //     console.log(res);
  //   }).catch(err => {
  //     console.log(err);
  //   });
  // }

  trackEvent(category: string, action: string, name?: string, value?: number) {
    // console.log('trackEvent');
    // console.log(category + ' - ' + action + ' - ' + name + ' - ' + value);
    this.matomoTracker.trackEvent(category, action, name, value);
    this.trackPageView();
  }

  trackLink(url: string, linkType: 'link' | 'download') {
    this.matomoTracker.trackLink(url, linkType);
    this.trackPageView();
  }

}


// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  authTokenKey: 'ac_to_p_b',
  currentSiteName: 'BetsBeats',
  apiUrl: 'https://api.betsbeats.com',
  websiteToken: 'cukrq81ZdNq9TbpofnVaT27N',
  web_chat_website_user_identify_hash: 'EfP2WpVuN3StbEbBKbVbDGS5',
  appleClientId: 'com.betsbeats.auth',
  tgBotId: '7070182673',
  tgBotName: 'BetsBeatsBot',
  facebookAppId: '1120170866344806',

  analytics: {
    id: 20,
    key: '2e8a9bf05ab50a6ae506c614b3071faa'
  },
  push: {
    key: '4d8e28e2fbf445c5e9d6'
  },
  firebaseConfig: {
    apiKey: "AIzaSyBzpcU8h9r1-rE3JGx5UvEJ-j63aVlvtUA",
    authDomain: "bets-beats.firebaseapp.com",
    projectId: "bets-beats",
    storageBucket: "bets-beats.firebasestorage.app",
    messagingSenderId: "1029141063601",
    appId: "1:1029141063601:web:1e007243cc21178540bf90",
    measurementId: "G-2JK2L1CGBB"
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.

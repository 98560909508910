export const COMMON: any = {
  CHANNEL_TYPES: ['mail', 'sms', 'telegram'],
  NOTIFICATIONS: ['waiting', 'processing', 'completed', 'cancelled'],
  COOKIE_KEY: ['bb_config'],
  EMAIL_REGEX: /[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,9}$/,
  LOGO_PATCH: 'assets/images/logo/betsbeats2.svg',
  LOGO_PATCH_OLD: 'assets/images/logo/logo_new.webp',
  SITE_SECTION: ['backend', 'profile', 'mobile'],
  SITE_SECTION_CONTROL: 'control',
  SITE_SECTION_BACKEND: 'backend',
  PRIORITIES: [{id: 0, name: 'low'}, {id: 1, name: 'medium'}, {id: 2, name: 'high'}],

  MIME_TYPES: ['image/bmp', 'image/gif', 'image/x-freehand', 'image/x-icon', 'image/jpeg', 'image/png', 'image/tiff'],

  LANGUAGE_LIST: [
    {code: 'en', label: 'English', moment: 'en', format: 'en-US'},
    {code: 'ja', label: '日本', moment: 'ja', format: 'ja-JP'},
    {code: 'es', label: 'Español', moment: 'es', format: 'es-ES'},
  ],

  TABLE: {
    PARAMS: {
      // 'created_at[orderBy]': 'desc'
      order: 'desc',
      orderBy: 'created_at',
      per_page: 50
    },
    ORDER_PARAMS: {
      order: 'asc',
      orderBy: 'order',
      per_page: 50
    },
    DEFAULT_SORT: {
      ACTIVE: 'created_at',
      DIRECTION: 'desc',
    },
    ID_PARAMS: {
      order: 'desc',
      orderBy: 'id',
      per_page: 100
    },
    PAGINATOR: {
      PAGE_SIZE: 50,
      PAGE_OPTIONS: [50, 100, 200, 500]
    },
    SORT: {
      DIRECTIONS: [
        {
          id: 'asc',
          name: 'Ascending'
        },
        {
          id: 'desc',
          name: 'Descending'
        }
      ]
    }

  },

  PRODUCTS: [
    {
      name: '1DAY ACCESS',
      slug: 'pr_1day',
      id: 'pr_1day',
      total: 100,
      image: 'assets/images/home/top-tipsters-con-bg-1.png',
      image_thumb: "assets/images/demo-profile.png",
      description: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Accusamus adipisci aliquam corporis cum debitis dicta dignissimos dolor dolorem dolorum eaque eos ex harum itaque maxime numquam odio optio, pariatur veniam!",
      product_attributes: [
        {
          "id": "atr_1day",
          "name": "1 days",
          "type": "day",
          "total": "10.00",
          data: {
            days: 1
          }
        }
      ]
    },
    {
      name: '2DAY ACCESS',
      slug: 'pr_3day',
      id: 'pr_3day',
      total: 100,
      image: 'assets/images/home/top-tipsters-con-bg-2.png',
      image_thumb: "assets/images/demo-profile.png",
      description: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Accusamus adipisci aliquam corporis cum debitis dicta dignissimos dolor dolorem dolorum eaque eos ex harum itaque maxime numquam odio optio, pariatur veniam!",
      product_attributes: [
        {
          "id": "atr_8o0OFtidm3b6Qzi8",
          "name": "2 days",
          "type": "day",
          "total": "20.00",
          data: {
            days: 2
          }
        }
      ]
    },
    {
      name: '3DAY ACCESS',
      slug: 'pr_7day',
      id: 'pr_7day',
      total: 100,
      image: 'assets/images/home/top-tipsters-con-bg-3.png',
      image_thumb: "assets/images/demo-profile.png",
      description: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Accusamus adipisci aliquam corporis cum debitis dicta dignissimos dolor dolorem dolorum eaque eos ex harum itaque maxime numquam odio optio, pariatur veniam!",
      product_attributes: [
        {
          "id": "atr_8o0OFtidm3b6Qzi8",
          "name": "7 days",
          "type": "day",
          "total": "70.00",
          data: {
            days: 7
          }
        }
      ]
    },
  ]
};

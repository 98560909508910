<div class="login-content">
  <div class="modal-header justify-content-center">
    <h4 class="title">Login to BetsBeats</h4>
  </div>
  <div class="modal-body">
    <div class="form-area">
      <form [formGroup]="form" (submit)="submit()">
        <div class="row">
          <div class="col-12">
            <div class="single-input">
              <img src="assets/images/icon/message-icon.png"
                   alt="image">
              <input type="text" placeholder="Enter Your Email" formControlName="username">
              <span class="error" *ngIf="form.get('username')?.touched && form.get('username')?.invalid">
                {{ _formUtilsService.getErrorMessage(form, 'username') }}
              </span>
            </div>
          </div>
          <div class="col-12">
            <div class="single-input">
              <img src="assets/images/icon/lock.png" alt="image">
              <input type="password" #passwordField placeholder="Password" formControlName="password">
              <span class="error" *ngIf="form.get('password')?.touched && form.get('password')?.invalid">
                {{ _formUtilsService.getErrorMessage(form, 'password') }}
              </span>
              <div class="suffix"
                   (click)="passwordField.type === 'password' ? passwordField.type = 'text' : passwordField.type = 'password'">
                <img width="28px" height="28px"
                     [src]=" passwordField.type === 'password' ?
                     'assets/images/icon/unhide.png' : 'assets/images/icon/hide.png'"
                     alt="">
              </div>
            </div>
          </div>
          <div class="col-12">
            <div
              class="forget d-flex align-items-center justify-content-between">
              <!--              <label class="checkbox-wrapper">Remember me-->
              <!--                <input type="checkbox" checked="checked">-->
              <!--                <span class="checkmark"></span>-->
              <!--              </label>-->
              <a href="javascript:void(0)" (click)="changeType($event)">Forgot Password?</a>
            </div>
          </div>
          <div class="btn-area">
            <button class="cmn-btn" type="submit"
                    [disabled]="isLoading"
                    [ngClass]="{'is-process': isLoading}">
              <span>LOGIN</span>
            </button>
          </div>
        </div>
      </form>
      <span class="or">or</span>
      <div class="reg-with">
        <a href="javascript:void(0)" class="cmn-btn">login with
          facebook</a>
        <a href="javascript:void(0)" class="cmn-btn second">Sign in with
          Google</a>
      </div>
    </div>
  </div>
</div>

<!-- Container -->

<ng-container *ngIf="_storageUtilsService.isTgPage; else def">
  <router-outlet></router-outlet>
</ng-container>
<ng-template #def>

  <app-header></app-header>

  <router-outlet></router-outlet>

  <app-footer></app-footer>

  <div id="scroll-arrow" (click)="scrollTop()" class="scrollToTop"><i class="fas fa-angle-double-up"></i></div>

  <div (click)="openPayForm()" class="basket"
       href="javascript:void(0)"
       [ngClass]="{'active pulse': isShowBasket}">
    <i class="fas fa-basket-shopping"></i>
  </div>

</ng-template>

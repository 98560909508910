import {Route} from '@angular/router';
import {LayoutComponent} from './layout/layout.component';
import {InitialDataResolver} from "./app.resolvers";

export const appRoutes: Route[] = [

  {
    path: '',
    component: LayoutComponent,
    resolve: {
      initialData: InitialDataResolver
    },
    data: {
      layout: 'centered'
    },
    children: [
      {
        path: '',
        loadChildren: () => import('app/modules/public/public.module').then(m => m.PublicModule)
      }
    ]
  },
  {
    path: '404-not-found',
    pathMatch: 'full',
    data: {title: 'Not Found'},
    loadChildren: () => import('app/modules/errors/errors.module').then(m => m.ErrorsModule)
  },
  {path: '**', redirectTo: '404-not-found'},

];

// Angular
import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {CustomCountdownComponent} from './custom-countdown.component';

@NgModule({
    declarations: [
        CustomCountdownComponent,
    ],
    imports: [
        CommonModule,
    ],
    exports: [
        CustomCountdownComponent
    ]
})
export class CustomCountdownModule {
}

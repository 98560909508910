import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {CommonModule} from '@angular/common';
import {FooterComponent} from "./footer.component";
import {ReactiveFormsModule} from "@angular/forms";

@NgModule({
  declarations: [
    FooterComponent
  ],
    imports: [
        RouterModule,
        CommonModule,
        ReactiveFormsModule
    ],
  exports: [
    FooterComponent
  ]
})
export class FooterModule {
}

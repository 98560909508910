// Angular
import {Component, Inject, OnInit, ChangeDetectionStrategy} from '@angular/core';

// RxJS
import {delay} from 'rxjs/operators';
import {of} from 'rxjs';
import {MAT_SNACK_BAR_DATA} from "@angular/material/snack-bar";

@Component({
    selector: 'app-action-notification',
    templateUrl: './action-notification.component.html',
    styleUrls: ['./action-notification.component.scss'],
    changeDetection: ChangeDetectionStrategy.Default
})
export class ActionNotificationComponent implements OnInit {
    /**
     * Component constructor
     *
     * @param data
     */
    constructor(@Inject(MAT_SNACK_BAR_DATA) public data: any) {
    }

    /**
     * On init
     */
    ngOnInit(): void {
        if (!this.data.showUndoButton || (this.data.undoButtonDuration >= this.data.duration)) {
            return;
        }

        this.delayForUndoButton(this.data.undoButtonDuration).subscribe(() => {
            this.data.showUndoButton = false;
        });
    }

    /*
     *	Returns delay
     *
     * @param timeToDelay: any
     */
    delayForUndoButton(timeToDelay: number): any {
        return of('').pipe(delay(timeToDelay));
    }
}

<div class="single-item notifications-area">
  <div class="notifications-btn"
       [ngClass]="{'active-dot': this.hasUnread}"
       (click)="toggle(not, overlay)">
    <img src="assets/images/icon/notifications.png" alt="icon">
  </div>
  <div #not class="main-area notifications-content">
    <div class="head-area d-flex justify-content-between">
      <div class="left d-flex align-items-center">
        <h5>Notifications</h5>
        <ng-container *ngIf="items?.length">
          <span class="mdr">{{ items.length }}</span>
        </ng-container>
      </div>
      <ng-container *ngIf="items?.length">
        <button class="clear-all" (click)="readAll(not, overlay)">
          <img src="assets/images/icon/delete.png" alt="icon" width="28" height="28">
        </button>
      </ng-container>
    </div>
    <ul>

      <ng-container *ngIf="items?.length; else noItems">
        <ng-container *ngFor="let item of items; let first = first">
          <li [ngClass]="{'border-area': first}">
            <a href="javascript:void(0)"
               [ngClass]="{'active': item.status !== 'read'}"
               (click)="navigate(item?.event?.type, not, overlay)">
              <!--          <div class="img-area">-->
              <!--            <img src="assets/images/latest-tips-1.png" alt="image">-->
              <!--          </div>-->
              <div class="text-area">
                <!--            <h6>Della Parker</h6>-->
                <p class="mdr">

                  <ng-container *ngIf="item?.event?.type === 'packagePurchaseSuccess'">
                    Package was successfully purchased
                  </ng-container>

                </p>
                <p class="mdr time-area">{{ item?.event?.created_at | localeDate: 'MMM D | LT' }}</p>
              </div>
            </a>
          </li>
        </ng-container>
      </ng-container>
      <ng-template #noItems>
        <li class="border-area">
          <div class="text-center">
            <p class="mdr">
              You have no notifications
            </p>
          </div>
        </li>
      </ng-template>
    </ul>
  </div>
</div>

<div class="overlay" #overlay (click)="toggle(not, overlay)"></div>

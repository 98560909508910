import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {AuthComponent} from './auth.component';
import {MAT_DIALOG_DATA, MatDialogModule, MatDialogRef} from "@angular/material/dialog";
import {MatIconModule} from "@angular/material/icon";
import {MatInputModule} from "@angular/material/input";
import {ReactiveFormsModule} from "@angular/forms";
import {MatCheckboxModule} from "@angular/material/checkbox";
import {RouterModule} from "@angular/router";
import {LoginComponent} from "./login/login.component";
import {NotificationUtilsService} from "@core/utils/notification-utils.service";
import {MatSnackBarModule} from "@angular/material/snack-bar";
import {ForgotPasswordComponent} from "./forgot-password/forgot-password.component";
import {SignUpComponent} from "./sign-up/sign-up.component";
import {CustomCountdownModule} from "@shared/components/custom-countdown/custom-countdown.module";
import {VerificationComponent} from "./verification/verification.component";
import {MatLegacyFormFieldModule} from "@angular/material/legacy-form-field";
import {NewPasswordComponent} from "./new-password/new-password.component";


@NgModule({
  declarations: [
    AuthComponent,
    LoginComponent,
    ForgotPasswordComponent,
    SignUpComponent,
    VerificationComponent,
    NewPasswordComponent
  ],
  imports: [
    CommonModule,
    MatDialogModule,
    MatIconModule,
    MatInputModule,
    ReactiveFormsModule,
    MatCheckboxModule,
    RouterModule,
    MatSnackBarModule,
    CustomCountdownModule,
    MatLegacyFormFieldModule
  ],
  providers: [
    NotificationUtilsService,
    {provide: MAT_DIALOG_DATA, useValue: null},
    {provide: MatDialogRef, useValue: {}}
  ],
  exports: [
    AuthComponent
  ]
})
export class AuthModule {
}

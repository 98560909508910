// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  authTokenKey: 'ac_to_b',
  currentSiteName: 'Free picks | Sports lines | Odd bet| Football Betting | Betting Picks',
  apiUrl: 'https://picks2.payments.dev.zenzenzen.net',
  websiteToken: 'RL8AbDk2BLPVTMGqtamSg6uc',
  web_chat_website_user_identify_hash: 'Epv3L8RmSRLRWon6tExWKkWg',
  appleClientId: 'com.dlucky.spreads.login',
  tgBotId: '502544293',
  tgBotName: 'Monitoring24_bot',
  facebookAppId: '1120170866344806',

  analytics: {
    id: 14,
    key: '2e8a9bf05ab50a6ae506c614b3071faa'
  },
  push: {
    key: 'cbe8996a4af4c51f0676'
  },
  firebaseConfig: {
    apiKey: "AIzaSyAUdw-seR5Nq9CrpIwbgFF-4ZEI6zrx5T8",
    authDomain: "betsbeats-f907c.firebaseapp.com",
    projectId: "betsbeats-f907c",
    storageBucket: "betsbeats-f907c.appspot.com",
    messagingSenderId: "737426726341",
    appId: "1:737426726341:web:f94fcdab5bf2000eac3fac",
    measurementId: "G-8G89WW5P0M"
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.

// common.service.ts
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Title } from '@angular/platform-browser';

@Injectable()
export class CommonService {

    constructor(
        public router: Router,
        private titleService: Title
    ) {
    }

    // Get Parent Route if any
    parentUrl(): string {
        return this.router.url.split('/')[1];
    }

    // Get Child Route if any
    childUrl(): string {
        return this.router.url.split('/')[2];
    }

    // Set Title
    setTitle(newTitle: string, fullTitle = false): any {
        let title = (newTitle ? newTitle : '');

        if (fullTitle) {
            title = newTitle;
        }

        this.titleService.setTitle(title);
    }

    // Uppercase First
    Ucase(name: string): string {
        return name.charAt(0).toUpperCase() + name.slice(1);
    }
}

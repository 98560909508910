import {Directive, ElementRef, EventEmitter, HostListener, Input, Output} from '@angular/core';

@Directive({
  selector: '[appCheckScroll]'
})
export class CheckScrollDirective {

  @Output() scrollEmit: EventEmitter<any> = new EventEmitter<any>();
  @Input() globalScroll: boolean = false;

  constructor(private _elementRef: ElementRef) {
  }

  @HostListener('document:scroll', ['$event'])

  onScroll($event: any): void {
    $event.preventDefault();
    if (this.globalScroll) {
      const item = document.getElementsByTagName('html')[0];
      return this.scrollEmit.emit(item);
    } else {
      const element = this._elementRef.nativeElement;
      const checkScroll = (element.scrollHeight - element.scrollTop) < (element.clientHeight + 100);
      if (checkScroll) {
        this.scrollEmit.emit(true);
      }
    }
  }
}

<footer class="footer-section">
  <div class="container pt-120">

    <ng-container *ngIf="!hideSubscribe">

      <div class="row justify-content-center">
        <div class="col-lg-10">
          <div class="newsletter">
            <div class="section-header text-center">
              <h3 class="title">Don't miss out on Fantastic News</h3>
              <p>Subscribe to our newsletter and be the first to receive news</p>
            </div>
            <form [formGroup]="form"
                  (submit)="send()">
              <div class="form-group d-flex align-items-center"
                   [ngClass]="{'invalid': form.invalid && form.get('email')?.touched}">
                <input type="email"
                       [formControlName]="'email'"
                       placeholder="Enter Your Email">
                <button [disabled]="btnDisabled"
                        [ngClass]="{'is-process': form.disabled}">
                  <img src="assets/images/icon/send-icon.png" alt="icon">
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </ng-container>

    <div class="footer-bottom-area pt-120">
      <div class="row">
        <div class="col-xl-12">
          <div class="menu-item">
            <a routerLink="/" class="logo">
              <img [src]="logoPatch" alt="logo">
            </a>

            <ul class="footer-link">
              <li><a routerLink="/">Home</a></li>
              <li><a routerLink="/posts/news">Sports News</a></li>
              <li><a routerLink="/posts/blog">Blog</a></li>
              <li><a routerLink="/about">About</a></li>
              <li><a routerLink="/new-affiliate">New Affiliate</a></li>
              <li><a routerLink="/contact-us">Contact</a></li>
              <li><a routerLink="/terms">Terms of Services</a></li>
              <li><a routerLink="/privacy-policy">Privacy</a></li>
            </ul>
          </div>
        </div>
        <div class="col-12">
          <div class="copyright">
            <div class="copy-area">
              <p> Copyright © <a routerLink="/">BetsBeats</a></p>
            </div>
            <div class="social-link d-flex align-items-center">
              <a href="/" target="_blank"><i class="fab fa-youtube"></i></a>
              <a href="/"><i class="fab fa-tiktok"></i></a>
              <a href="/"><i class="fab fa-instagram"></i></a>
              <a href="/"><i class="fab fa-twitter"></i></a>
            </div>
          </div>
          <div class="not-gambling">
            This is not a gambling Website nor it represents casino nor is in any way related to any type and kind of
            bets operations. The sole purpose of the Website is to provide visitors and players with entertainment and
            fun.
          </div>
          <div class="not-gambling" id="changewin">
            <small><span>*</span> "WIN" means an opportunity and a chance to win, but it is not guaranteed.</small>
          </div>
        </div>
      </div>
    </div>
  </div>
</footer>

<ng-container *ngIf="countdown && type != 'onlyTime'">
  <div class="times">
    <ng-container *ngIf="countdown.years()">
      <p class="mdr">{{ countdown.years() }}Y</p>
    </ng-container>
    <ng-container *ngIf="countdown.months()">
      <p class="mdr">{{ countdown.months() }}M</p>
    </ng-container>
    <ng-container *ngIf="countdown.days() || countdown.months() || countdown.years()">
      <p class="mdr">{{ countdown.days() }}D</p>
    </ng-container>
    <p class="mdr">{{ countdown.hours() }}H</p>
    <ng-container *ngIf="!countdown.years()">
      <p class="mdr">{{ countdown.minutes() }}M</p>
    </ng-container>
    <ng-container *ngIf="!countdown.months()">
      <p class="mdr">{{ countdown.seconds() }}S</p>
    </ng-container>
  </div>
</ng-container>
<ng-container *ngIf="countdown && time && type === 'onlyTime'">
  <ng-container *ngIf="!isAfter else emptyTimeS"> 00:{{ time }}</ng-container>
  <ng-template #emptyTimeS>00:00</ng-template>
</ng-container>

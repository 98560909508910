<div class="modal-dialog auth">
  <div class="modal-content">
    <div class="tab-content">
      <div class="tab-pane fade show active" id="loginArea" role="tabpanel"
           aria-labelledby="loginArea-tab">
        <ng-container *ngIf="type === 'signUp'">
          <app-sign-up [dialogRef]="dialogRef"></app-sign-up>
        </ng-container>
        <ng-container *ngIf="type === 'logIn'">
          <app-login [dialogRef]="dialogRef"></app-login>
        </ng-container>
        <ng-container *ngIf="type === 'forgotPassword'">
          <app-forgot-password [hideLogin]="hideLogin"></app-forgot-password>
        </ng-container>
        <ng-container *ngIf="type === 'verification'">
          <app-verification [dialogRef]="dialogRef"></app-verification>
        </ng-container>
        <ng-container *ngIf="type === 'newPassword'">
          <app-new-password [dialogRef]="dialogRef"></app-new-password>
        </ng-container>
      </div>
    </div>
    <ul class="nav log-reg-btn justify-content-center pt-3">
      <li class="bottom-area" *ngIf="type === 'signUp'">
        <button class="nav-link" id="loginArea-tab" type="button"
                (click)="changeType('forgotPassword')">
          Already a Member? <span>Login</span>
        </button>
      </li>
      <li class="bottom-area" *ngIf="type === 'logIn'">
        <button class="nav-link" id="regArea-tab" type="button" role="tab"
                (click)="changeType('signUp')">
          Not a member yet? <span>Register now</span>
        </button>
      </li>
    </ul>
  </div>
</div>

import {Pipe, PipeTransform} from '@angular/core';
import moment from "moment/moment";
import momentTz from "moment-timezone";

@Pipe({
  name: 'localeDate',
  pure: false
})
export class LocaleDatePipe implements PipeTransform {

  constructor() {}

  transform(value: any, pattern?: string): any {
    if (pattern === 'la_time') {
      // d MMM y, h:mm: A
      return momentTz.tz(value, 'America/Los_Angeles').format('y MMM D | LT');
    }
    return moment(value).format(pattern);
  }
}

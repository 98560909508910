import {AfterViewInit, Component} from '@angular/core';
import {PusherService} from "@core/utils/pusher.service";
import {TracardiService} from "@core/utils/tracardi.service";
import {StorageUtilsService} from "@core/utils/storage.utils.service";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styles: []
})
export class AppComponent implements AfterViewInit {

  constructor(private _pusherService: PusherService,
              private _tracardiService: TracardiService,
              private _storageUtilsService: StorageUtilsService) {
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this._tracardiService.track('page-view', {
        "url": window.location.href,
        "pageTitle": document.getElementsByTagName("title")[0].innerHTML || ''
      })
      this.loadGtag();
      this.subscribeChatwootMessage();
      this._pusherService.subscribePusher();
    }, 1000)
  }

  loadGtag() {
    this._storageUtilsService.loadJs('https://www.googletagmanager.com/gtag/js?id=G-GZHCQCW0GY', 'gtag', 'dataLayer')
      .then(async _ => {
        const dataLayer = (window as any)['dataLayer'] || []

        dataLayer.push('js', new Date())
        dataLayer.push('config', 'G-GZHCQCW0GY')
      })
  }

  subscribeChatwootMessage() {
    if (window?.addEventListener) {
      window.addEventListener("message", event => {
        if (event?.data && typeof event.data === 'string' && event.data?.includes('appContext') && !event.data.includes('iFrameResizerChild')) {
          const e = JSON.parse(event.data);
          if (e.event && e.event.toLowerCase() === 'appContext'.toLowerCase()) {
            localStorage.setItem('appcontext', event.data);
          }
        }
      })
    }
  }
}

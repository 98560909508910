import {Directive, ElementRef, HostListener, Input, OnInit} from '@angular/core';

@Directive({
  selector: '[appAnimate]'
})
export class AnimateDirective implements OnInit{

  @Input() autoAnimate = false;

  constructor(private _elementRef: ElementRef) {

    const element = this._elementRef.nativeElement;
    element.style.visibility = 'hidden';
    element.style.animationName = 'none';

  }

  ngOnInit(){
    if (this.autoAnimate) {
      this.animate();
    }
  }

  @HostListener('window:scroll', [])
  onWindowScroll() {
    this.animate();
  }

  animate() {
    const element = this._elementRef.nativeElement;
    if (element.classList.contains('isAnimated')) {
      return;
    }
    if (this.autoAnimate || this.isVisible()) {
      element.style.visibility = '';
      element.style.animationName = '';
      element.classList.add('animated');
      element.classList.add('isAnimated');
      setTimeout(() => {
        element.classList.remove('animated');
      }, 1000)
    } // else{
    //   element.classList.add('before-animation');
    // }
  }

  isVisible() {
    let a = this._elementRef.nativeElement;
    let c = window.pageYOffset,
      d = c + Math.min(window.innerHeight, this.k()), e = this.offsetTop(a), f = e + a.clientHeight;
    return d >= e && f >= c
  }

  offsetTop(a: any) {
    for (; void 0 === a.offsetTop;) a = a.parentNode;
    for (var b = a.offsetTop; a.offsetParent;) a = a.offsetParent; b += a.offsetTop;
    return b
  }

  k() {
    return "innerHeight" in window ? window.innerHeight : document.documentElement.clientHeight
  }
}

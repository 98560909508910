import {ChangeDetectorRef, Component, Input, OnDestroy, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {FormUtilsService} from "@core/utils/form-utils.service";
import {Subject, takeUntil} from "rxjs";
import {AuthService} from "@core/auth/auth.service";
import {NotificationUtilsService} from "@core/utils/notification-utils.service";

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
})
export class LoginComponent implements OnInit, OnDestroy {

  @Input() dialogRef: any = null;

  form: FormGroup = new FormGroup<any>({});
  isLoading: boolean = false;

  private _unsubscribeAll = new Subject();

  constructor(private fb: FormBuilder,
              public _formUtilsService: FormUtilsService,
              private cdr: ChangeDetectorRef,
              private _notificationUtilsService: NotificationUtilsService,
              public _authService: AuthService) {


  }

  ngOnInit() {
    this._authService._isLogin = true;
    this.form = this.fb.group({
      username: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required, Validators.minLength(6)]]
    });
  }

  ngOnDestroy() {
    this._authService._isLogin = false;
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.unsubscribe();
  }

  changeType(e: any) {
    e.stopPropagation();
    e.preventDefault();
    this._authService.authType = 'forgotPassword';
  }


  submit() {
    if (this.form.invalid) {
      this._formUtilsService.validation(this.form);
      return;
    }
    const data = this.form.getRawValue();
    this.form.disable();
    this.isLoading = true;

    this._authService.signIn(data)
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(_ => {
          this.isLoading = false;
          this.dialogRef.close();
        },
        error => {
          if (error.isAlreadyLogin) {
            this.isLoading = false;
            this.dialogRef.close();
            return;
          }
          this.isLoading = false;
          this.form.enable();
          const message = this._formUtilsService.prepareServerError(error, this.form, this.cdr);
          this._notificationUtilsService.showActionNotification(message?.content, message?.type);
        });
  }

}

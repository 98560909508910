import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {BehaviorSubject, Observable} from 'rxjs';
import {map, tap} from 'rxjs/operators';
import {environment} from '@environments/environment';
import {User} from "./user.types";
import {MatomoService} from "@core/utils/matomo.service";

@Injectable({
  providedIn: 'root'
})
export class UserService {
  // Observables
  private _user: BehaviorSubject<User> = new BehaviorSubject<User>({
    id: '',
    first_name: '',
    last_name: '',
    email: '',
    balance: '',
    role_id: null
  });
  private _affiliateProfile: BehaviorSubject<any> = new BehaviorSubject<any>({});
  private url = environment.apiUrl;

  /**
   * Constructor
   *
   * @param {HttpClient} _httpClient
   * @param _matomoService
   */
  constructor(
    private _httpClient: HttpClient,
    private _matomoService: MatomoService,
  ) {}

  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------

  /**
   * Setter & getter for user
   *
   * @param value
   */
  set user(value: any) {
    // Store the value
    this._user.next(value);
  }

  get user(): any {
    return this._user.getValue();
  }

  get isAdmin(): any {
    return this.user.role_id === 1;
  }

  get user$(): Observable<any> {
    return this._user.asObservable();
  }
  set affiliateProfile(value: any) {
    // Store the value
    this._affiliateProfile.next(value);
  }
  get affiliateProfile(): any {
    return this._affiliateProfile.getValue();
  }
  get affiliateProfile$(): Observable<any> {
    return this._affiliateProfile.asObservable();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Update the user data
   *
   * @param user
   */
  update(user: any): Observable<any> {
    const url = '/api/profile/me';
    return this._httpClient.patch(this.url + url, user)
      .pipe(
        tap((res: any) => {
          if (res.data) {
            this._user.next(res.data);
          }
        })
      );
  }
  upload(data: any, params: any): Observable<any> {
    return this._httpClient.post<any>(this.url + '/api/public/uploads/images', data, {params});
  }

  addSocial(data: any, type: string): Observable<any> {
    return this._httpClient.post<any>(this.url + '/api/profile/me/socials_add/' + type, data);
  }

  // getSocialUrl(type: string): Observable<any> {
  //   return this._httpClient.get<any>(this.url + '/api/public/auth/social/' + type);
  // }

  sendTokenFirebase(data: any): Observable<any> {
    return this._httpClient.post<any>(`${this.url}/api/public/firebase/subscribe_to_topic `, data);
  }

  sendTokenFirebaseUser(data: any): Observable<any> {
    const url = '/api/profile/me/set_fcm_token';
    return this._httpClient.post<any>(this.url + url, data);
  }

  unsetSocial(data: any, type: string): Observable<any> {
    return this._httpClient.post<any>(this.url + '/api/profile/me/socials_unset/' + type, data);
  }

  addToWishlist(data: any): Observable<any> {
    return this._httpClient.post<any>(this.url + '/api/profile/wishlists', data);
  }
  removeFromWishlist(data: any): Observable<any> {
    const options = {
      body: data,
    };
    return this._httpClient.delete<any>(this.url + '/api/profile/wishlists', options);
  }

  updatePass(data: any): Observable<any> {
    const url = '/api/profile/me/password';
    return this._httpClient.patch(this.url + url, data);
  }

  loadUser(): Observable<any> {
    // const url = this._storageUtilsService.isControl ? '/api/control/me' : '/api/profile/me';
    const url = '/api/profile/me';
    return this._httpClient.get(this.url + url).pipe(
      map((res: any) => {
        this._user.next(res['data']);
        this._matomoService.setUserId(this.user.id);
        return res['data'];
      })
    );
  }

  loadAffiliateProfile(): Observable<any> {
    return this._httpClient.get(this.url + '/api/profile/affiliate').pipe(
      map((res: any) => {
        this._affiliateProfile.next(res['data']);
        return res['data'];
      })
    );
  }

  updateAffiliateProfile(data: any): Observable<any> {
    const url = '/api/profile/affiliate';
    return this._httpClient.patch(this.url + url, data)
      .pipe(
        tap((res: any) => {
          if (res.data) {
            this._affiliateProfile.next(res.data);
          }
        })
      );
  }

  loadSocials(): Observable<any> {
    return this._httpClient.get(this.url + '/api/profile/me/socials').pipe(
      map((res: any) => res['data'])
    );
  }

  removeUser(): void {
    this._user.next({
      id: '',
      first_name: '',
      last_name: '',
      email: '',
      role_id: null
    });
  }
}

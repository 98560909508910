import {
  ChangeDetectorRef,
  Component, EventEmitter,
  Input, OnChanges, Output,
} from '@angular/core';
import moment from "moment";
import momentTz from "moment-timezone";

@Component({
  selector: 'app-custom-countdown',
  templateUrl: './custom-countdown.component.html',
  styleUrls: ['./custom-countdown.component.scss'],
})
export class CustomCountdownComponent implements OnChanges {

  @Input() date: string | null = ''
  @Input() title: string = ''
  @Input() type: string = '';


  @Output() endEvent?: any = new EventEmitter();

  countdown: any = '';
  clearInterval: any;
  time: any = '';

  constructor(private _changeDetectorRef: ChangeDetectorRef) {
  }

  ngOnChanges() {

    this.countdown = '';
    if (this.clearInterval) {
      clearInterval(this.clearInterval);
    }
    if (!this.date?.length) {
      return;
    }

    // this.date = moment().add('week', 1).format();

    const date = this.type === 'onlyTime' ? moment().format('YYYY-MM-DD HH:mm:ss')
      : momentTz.tz(new Date(), 'America/Los_Angeles').format('YYYY-MM-DD HH:mm:ss')
    const isTimeBefore = moment(date).isBefore(this.date);
    if (isTimeBefore) {
      let diffTime = moment(this.date).unix() - moment(date).unix();
      let duration: any = moment.duration(diffTime * 1000, 'milliseconds');

      duration = this.action(duration);
      this.clearInterval = setInterval(() => {
        duration = this.action(duration);
      }, 1000);
    }
  }

  action(duration: any) {
    if (duration._milliseconds <= 0) {
      this.endEvent.next(true);
      return clearInterval(this.clearInterval);
    } else {
      this.countdown = moment.duration(duration - 1000, 'milliseconds');
      duration = this.countdown;
      if (this.type === 'onlyTime') {
        const s = duration.seconds();
        const m = duration.minutes();
        this.time = (m < 10 ? '0' + m : m || '00') + ':' + (s < 10 ? '0' + s : s || '00');
      }
      this._changeDetectorRef.markForCheck();
    }
    return duration;
  }

  get isAfter() {
    return moment().isAfter(this.date);
  }
}


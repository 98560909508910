import { NgModule } from '@angular/core';
import {RouterModule} from '@angular/router';
import { MatMenuModule } from '@angular/material/menu';
import {CommonModule} from '@angular/common';
import {MenuNotificationComponent} from "./menu-notification.component";
import {PipeModule} from "@shared/pipe/pipe.module";

@NgModule({
    declarations: [
        MenuNotificationComponent
    ],
  imports: [
    CommonModule,
    MatMenuModule,
    RouterModule,
    PipeModule,
  ],
    exports     : [
        MenuNotificationComponent
    ]
})
export class MenuNotificationModule
{
}

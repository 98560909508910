import {Component, OnInit} from '@angular/core';
import {Router} from "@angular/router";
import {COMMON} from "@shared/contstants/common.const";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {PublicService} from "../../../../../modules/public/public.service";
import {NotificationUtilsService} from "@core/utils/notification-utils.service";
import {StorageUtilsService} from "@core/utils/storage.utils.service";

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  logoPatch = COMMON.LOGO_PATCH;

  hideSubscribe: boolean = this._storageUtilsService.get('subscribeSportPicks') || false;
  form: FormGroup = new FormGroup<any>({});
  btnDisabled = false;

  constructor(private _publicService: PublicService,
              private _storageUtilsService: StorageUtilsService,
              private _notificationService: NotificationUtilsService,
              private _formBuilder: FormBuilder) {
    // this.hideSubscribe = this._router.url.includes('subscribe');
  }

  ngOnInit(): void {
    this.form = this._formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      subscription_period: ['daily'],
      unsubscribed: 0
    })
  }

  send(): void {
    if (!this.form.valid) {
      this.form.get('email')?.markAsTouched();
      return;
    }

    this.form.disable();
    const value = this.form.getRawValue();
    this.btnDisabled = true;
    this._publicService.sendSubscribe(value).subscribe((res: any) => {
      if (res && res.data) {
        this.form.enable();
        this.form.get('email')?.setErrors(null);
        this._storageUtilsService.set('subscribeSportPicks', !value.unsubscribed);
        const text = value.unsubscribed ? 'Thank You. Subscription Settings Saved' :
          'Thank you! You will now receive sports information and on occasion free plays from us!';
        this._notificationService.showActionNotification(text, 1);

        // this._matomoService.trackEvent('Subscription', 'Submit', this.form.get('email')?.value);

        this.hideSubscribe = true;
      }
    }, (res: any) => {
      this.form.enable();
      this.btnDisabled = false;
      const errorText = 'There was an error loading the data. Please check the fields and try again.';
      return this._notificationService.showActionNotification(errorText, 0);
    });
  }

  get currentYear(): number {
    return new Date().getFullYear();
  }

}

<div class="reg-content regMod">
  <div class="modal-header justify-content-center">
    <div class="top-area text-center">
      <h4 class="title">New Password</h4>
    </div>
  </div>
  <div class="modal-body">
    <div class="form-area">
      <form (submit)="submit()" [formGroup]="form">
        <div class="row">
          <div class="col-12">
            <div class="single-input">
              <img src="assets/images/icon/lock.png" alt="image">
              <input type="password" #passwordField placeholder="New Password" formControlName="new_password">
              <div class="suffix"
                   (click)="passwordField.type === 'password' ? passwordField.type = 'text' : passwordField.type = 'password'">
                <img width="28px" height="28px"
                     [src]=" passwordField.type === 'password' ?
                     'assets/images/icon/unhide.png' : 'assets/images/icon/hide.png'"
                     alt="">
              </div>
              <span class="error" *ngIf="form.get('new_password')?.touched && form.get('new_password')?.invalid">
                {{ _formUtilsService.getErrorMessage(form, 'new_password') }}
              </span>
            </div>
          </div>
          <div class="col-12">
            <div class="single-input">
              <img src="assets/images/icon/lock.png" alt="image">
              <input type="password" placeholder="New Password Confirmation"
                     #passwordField1
                     formControlName="new_password_confirmation">
              <div class="suffix"
                   (click)="passwordField1.type === 'password' ? passwordField1.type = 'text' : passwordField1.type = 'password'">
                <img width="28px" height="28px"
                     [src]=" passwordField1.type === 'password' ?
                     'assets/images/icon/unhide.png' : 'assets/images/icon/hide.png'"
                     alt="">
              </div>
              <span class="error" *ngIf="form.get('new_password_confirmation')?.touched && form.get('new_password_confirmation')?.invalid">
                {{ _formUtilsService.getErrorMessage(form, 'new_password_confirmation') }}
              </span>
            </div>
          </div>

          <div class="btn-area">
            <button class="cmn-btn" type="submit"
                    [disabled]="isLoading"
                    [ngClass]="{'is-process': isLoading}">
              <span>Confirm</span>
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>

import {Directive, ElementRef, HostBinding, Input} from '@angular/core';
import {HighlightType} from "../contstants/highlight.const";

@Directive({
  selector: '[appHighlight]'
})

export class HighlightDirective {
  @Input() appHighlight: boolean | undefined = true;
  @Input() highlightValue: string = '';

  constructor(private _elementRef: ElementRef) {
  }

  @HostBinding('style.color')
  public get color(): string {
    if (this.appHighlight) {
      return this.highlight();
    }
    return ''
  }

  highlight(): any {
    let value = this.highlightValue?.length ? this.highlightValue.toLowerCase() :
      this._elementRef.nativeElement.innerText.trim().toLowerCase();
    let color = '#fff';
    if (typeof value === 'number') {
      value = value ? 'yes' : 'no';
    }
    for (let field in HighlightType) {
      if (field === value) {
        color = HighlightType[field as keyof typeof HighlightType];
      }
    }
    return color;
  }
}

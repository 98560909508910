<!-- Container -->
<app-header></app-header>

<router-outlet></router-outlet>

<app-footer></app-footer>

<a id="scroll-arrow" (click)="scrollTop()" class="scrollToTop"><i class="fas fa-angle-double-up"></i></a>

<a (click)="openPayForm()" class="basket" [ngClass]="{'active pulse': isShowBasket}">
  <i class="fas fa-basket-shopping"></i>
</a>

import {NgModule} from "@angular/core";


import {MatButtonToggleModule} from "@angular/material/button-toggle";
import {MatMenuModule} from "@angular/material/menu";
import {MatButtonModule} from "@angular/material/button";
import {MatCheckboxModule} from "@angular/material/checkbox";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatInputModule} from "@angular/material/input";
import {MatSelectModule} from "@angular/material/select";
import {MatDatepickerModule} from "@angular/material/datepicker";
import {MatIconModule} from "@angular/material/icon";
import {MatMomentDateModule} from "@angular/material-moment-adapter";
import {MatPaginatorModule} from "@angular/material/paginator";
import {MatTableModule} from "@angular/material/table";
import {MatTooltipModule} from "@angular/material/tooltip";
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";
import {MatExpansionModule} from "@angular/material/expansion";
import {MatSlideToggleModule} from "@angular/material/slide-toggle";
import {MatAutocompleteModule} from "@angular/material/autocomplete";
import {MatRadioModule} from "@angular/material/radio";
import {MatSortModule} from "@angular/material/sort";
import {MAT_DATE_FORMATS} from "@angular/material/core";
import {MatDividerModule} from '@angular/material/divider';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatProgressBarModule} from "@angular/material/progress-bar";
import {MatTabsModule} from "@angular/material/tabs";
import {MatDialogModule} from "@angular/material/dialog";


@NgModule({
    imports: [
        MatButtonToggleModule,
        MatMenuModule,
        MatButtonModule,
        MatCheckboxModule,
        MatFormFieldModule,
        MatInputModule,
        MatSelectModule,
        MatDatepickerModule,
        MatIconModule,
        MatMomentDateModule,
        MatPaginatorModule,
        MatTableModule,
        MatTooltipModule,
        MatProgressSpinnerModule,
        MatExpansionModule,
        MatSlideToggleModule,
        MatAutocompleteModule,
        MatRadioModule,
        MatSortModule,
        MatDividerModule,
        MatSidenavModule,
        MatProgressBarModule,
        MatDialogModule,
        MatTabsModule
    ],
    providers: [
        {
            provide: MAT_DATE_FORMATS,
            useValue: {
                display: {
                    dateInput: 'MM/DD/yyyy',
                    monthYearLabel: 'MMM YYYY',
                    dateA11yLabel: 'LL',
                    monthYearA11yLabel: 'MMMM YYYY'
                }
            }
        }
    ],
    exports: [
        MatButtonToggleModule,
        MatMenuModule,
        MatButtonModule,
        MatCheckboxModule,
        MatFormFieldModule,
        MatInputModule,
        MatSelectModule,
        MatDatepickerModule,
        MatIconModule,
        MatMomentDateModule,
        MatPaginatorModule,
        MatTableModule,
        MatTooltipModule,
        MatProgressSpinnerModule,
        MatExpansionModule,
        MatSlideToggleModule,
        MatAutocompleteModule,
        MatRadioModule,
        MatSortModule,
        MatDividerModule,
        MatSidenavModule,
        MatProgressBarModule,
        MatDialogModule,
        MatTabsModule,
    ],
})
export class MaterialModule {
}

<div class="reg-content regMod">
  <div class="modal-header justify-content-center">
    <div class="top-area text-center">
      <h4 class="title">Welcome To BetsBeats</h4>
      <p class="pb-3">Hundreds of Verified Tipsters. Winning Tips. Unbeatable Value.
      </p>
    </div>
  </div>
  <div class="modal-body">
    <div class="form-area">
      <form (submit)="submit()" [formGroup]="form">
        <div class="row">
          <div class="col-12">
            <div class="single-input">
              <img src="assets/images/icon/message-icon.png"
                   alt="image">
              <input type="text" placeholder="Enter Your Email" formControlName="email">
              <span class="error" *ngIf="form.get('email')?.touched && form.get('email')?.invalid">
                {{ _formUtilsService.getErrorMessage(form, 'email') }}
              </span>
            </div>
          </div>
          <div class="col-12">
            <div class="single-input">
              <img src="assets/images/icon/user-icon.png"
                   alt="image">
              <input type="text" placeholder="First Name" formControlName="first_name">
              <span class="error" *ngIf="form.get('first_name')?.touched && form.get('first_name')?.invalid">
                {{ _formUtilsService.getErrorMessage(form, 'first_name') }}
              </span>
            </div>
          </div>
          <div class="col-12">
            <div class="single-input">
              <img src="assets/images/icon/user-icon.png"
                   alt="image">
              <input type="text" placeholder="Last Name" formControlName="last_name">
              <span class="error" *ngIf="form.get('last_name')?.touched && form.get('last_name')?.invalid">
                {{ _formUtilsService.getErrorMessage(form, 'last_name') }}
              </span>
            </div>
          </div>
          <div class="col-12">
            <div class="single-input">
              <img src="assets/images/icon/lock.png" alt="image">
              <input type="password" #passwordField placeholder="Password" formControlName="password">
              <div class="suffix"
                   (click)="passwordField.type === 'password' ? passwordField.type = 'text' : passwordField.type = 'password'">
                <img width="28px" height="28px"
                     [src]=" passwordField.type === 'password' ?
                     'assets/images/icon/unhide.png' : 'assets/images/icon/hide.png'"
                     alt="">
              </div>
              <span class="error" *ngIf="form.get('password')?.touched && form.get('password')?.invalid">
                {{ _formUtilsService.getErrorMessage(form, 'password') }}
              </span>
            </div>
          </div>
          <div class="col-12">
            <div class="single-input">
              <img src="assets/images/icon/lock.png" alt="image">
              <input type="password" placeholder="Password Confirmation"
                     #passwordField1
                     formControlName="password_confirmation">
              <div class="suffix"
                   (click)="passwordField1.type === 'password' ? passwordField1.type = 'text' : passwordField1.type = 'password'">
                <img width="28px" height="28px"
                     [src]=" passwordField1.type === 'password' ?
                     'assets/images/icon/unhide.png' : 'assets/images/icon/hide.png'"
                     alt="">
              </div>
              <span class="error" *ngIf="form.get('password_confirmation')?.touched && form.get('password_confirmation')?.invalid">
                {{ _formUtilsService.getErrorMessage(form, 'password_confirmation') }}
              </span>
            </div>
          </div>
          <div class="col-12">
            <div
              class="forget d-flex align-items-center justify-content-between">
              <label class="checkbox-wrapper">
                I Have Read And Agree To The Website Terms And Conditions
                <input type="checkbox" checked="checked" formControlName="terms">
                <span class="checkmark"></span>
                <span class="error" *ngIf="form.get('terms')?.touched && form.get('terms')?.invalid">
                {{ _formUtilsService.getErrorMessage(form, 'terms') }}
              </span>
              </label>
            </div>
          </div>
          <div class="btn-area">
            <button class="cmn-btn" type="submit"
                    [disabled]="isLoading"
                    [ngClass]="{'is-process': isLoading}">
              <span>Continue</span>
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>

import {EventEmitter, Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable, of, throwError} from 'rxjs';
import {catchError, map, switchMap, tap} from 'rxjs/operators';

import {environment} from '@environments/environment';

import {UserService} from '../../layout/common/user/user.service';
import {StorageUtilsService} from '../utils/storage.utils.service';
import {Router} from '@angular/router';

@Injectable()
export class AuthService {
  // Private
  _authenticated: boolean;
  _isLogin: boolean = false;

  private url: string = environment.apiUrl;
  _authType: EventEmitter<any> = new EventEmitter();

  _resetPassData: {
    email: string | null,
    code: string | null
  } = {email: null, code: null};

  /**
   * Constructor
   *
   * @param {HttpClient} _httpClient
   * @param userService
   * @param _storageUtilsService
   * @param _router
   */
  constructor(
    private _httpClient: HttpClient,
    private userService: UserService,
    private _storageUtilsService: StorageUtilsService,
    private _router: Router
  ) {
    // Set the defaults
    this._authenticated = false;
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------

  /**
   * Setter & getter for access token
   */
  set accessToken(token: string) {
    localStorage.setItem(environment.authTokenKey, token);
  }

  get accessToken(): string {
    return localStorage.getItem(environment.authTokenKey) || '';
  }

  set authType(value: any) {
    this._authType.emit(value);
  }

  get authType$(): Observable<any> {
    return this._authType.asObservable();
  }

  get hasAccessToken(): boolean {
    return !!this.accessToken?.length;
  }

  removeToken(key: string): void {
    if (localStorage.getItem(key)) {
      localStorage.removeItem(key);
    }
  }

  setToken(token: string): void {
    // Store the access token in the local storage
    this.accessToken = token;

    // Set the authenticated flag to true
    this._authenticated = true;

    this.userService.loadUser().subscribe();
    // this.getProfilePermissions().subscribe();
  }

  loadUser(): Observable<any> {
    return this.userService.loadUser();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  getSiteConfig(): Observable<any> {
    return this._httpClient.get(this.url + '/api').pipe(
      map((res: any) => {
          this._storageUtilsService.setConfig(res['data']);
          return of(res['data']);
        }
      ));
  }

  /**
   * Sign in
   *
   * @param credentials
   */
  signIn(credentials: any): Observable<any> {
    // Throw error, if the user is already logged in
    if (this.hasAccessToken) {
      this._authenticated = true;
      return throwError({isAlreadyLogin: true});
    }

    // Reset support chat
    if ((window as any)['$chatwoot']) {
      (window as any)['$chatwoot'].reset();
    }

    return this._httpClient.post(this.url + '/api/public/auth/login', credentials).pipe(
      switchMap((response: any) => {
        this.setToken(response.access_token);
        return of(response);
      })
    );
  }

  /**
   * Sign in
   *
   * @param credentials
   */
  signUp(credentials: any): Observable<any> {
    return this._httpClient.post(this.url + '/api/public/auth/registration', credentials).pipe(
      switchMap((response: any) => {

        this.setToken(response.access_token);
        return of(response);
      })
    );
  }

  resetPassword(data?: any): Observable<any> {
    if (data?.email) {
      this._resetPassData.email = data.email;
    }
    return this._httpClient.post(this.url + '/api/public/auth/password_reset_request ', this._resetPassData);
  }

  resetPasswordValidate(data: any): Observable<any> {
    if (data?.code) {
      this._resetPassData.code = data.code;
    }
    return this._httpClient.post(this.url + '/api/public/auth/password_reset_validate', this._resetPassData).pipe(
      switchMap((response: any) => {
        return of(response);
      })
    );
  }
  recoveryPassword(data: any): Observable<any> {
    return this._httpClient.post(this.url + '/api/public/auth/password_reset', {...this._resetPassData, ...data}).pipe(
      switchMap((response: any) => {
        if (response?.access_token?.length) {
          this.setToken(response.access_token);
        }
        return of(response);
      })
    );
  }

  /**
   * Sign out
   */
  signOut(): Observable<any> {
    // Reset support chat
    if ((window as any)['$chatwoot']) {
      (window as any)['$chatwoot'].reset();
    }

    // Remove the access token from the local storage
    this.removeToken(environment.authTokenKey);
    this.userService.removeUser();
    this._storageUtilsService.clear();
    // Set the authenticated flag to false
    this._authenticated = false;

    // Return the observable
    return of(true);
  }

  /**
   * Check the authentication status
   */
  check(): Observable<boolean> {
    // Check if the user is logged in
    if (this._authenticated) {
      return of(true);
    }

    // Check the access token availability
    if (this.hasAccessToken) {
      this._authenticated = true;
      return of(true);
    }

    return of(false);
  }

  navigate(path: string = ''): void {
    this._router.navigateByUrl(path);
  }

}

/** Angular */
import {Injectable} from '@angular/core';

import {ActionNotificationComponent} from "@shared/components/action-natification/action-notification.component";
import {MatSnackBar} from "@angular/material/snack-bar";

export enum MessageType {
    Error,
    Success,
    Create,
    Update,
    Delete,
    Read,
}

@Injectable()
export class NotificationUtilsService {
    filter: any;

    constructor(private _snackBar: MatSnackBar) {
    }

    /**
     * Showing (Mat-Snackbar) Notification
     *
     * @param _message
     * @param _type
     * @param _duration
     * @param _showCloseButton
     * @param _showUndoButton
     * @param _verticalPosition
     * @param _undoButtonDuration
     * @param _horizontalPosition
     */
    showActionNotification(
        _message: string,
        _type: MessageType = MessageType.Success,
        _duration: number = 3000,
        _showCloseButton: boolean = false,
        _showUndoButton: boolean = false,
        _undoButtonDuration: number = 3000,
        _verticalPosition: 'top' | 'bottom' = 'top',
        _horizontalPosition: 'right' | 'start' | 'center' | 'end' | 'left'  =  'right'
    ): any {
        const _data = {
            message: _message,
            snackBar: this._snackBar,
            showCloseButton: _showCloseButton,
            showUndoButton: _showUndoButton,
            undoButtonDuration: _undoButtonDuration,
            verticalPosition: _verticalPosition,
            horizontalPosition: _horizontalPosition,
            type: _type,
            action: 'Undo'
        };
        return this._snackBar.openFromComponent(ActionNotificationComponent, {
            duration: _duration,
            data: _data,
            verticalPosition: _verticalPosition,
            horizontalPosition: _horizontalPosition,
        });
    }

}

import {Inject, Injectable, Injector} from '@angular/core';
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {AuthService} from '../auth/auth.service';

import {WINDOW} from './window.provider';
import {StorageUtilsService} from './storage.utils.service';
import {MatomoTracker} from "ngx-matomo";
import {CookieService} from "ngx-cookie-service";

@Injectable({
  providedIn: 'root'
})
export class Interceptor implements HttpInterceptor {

  visitorId = '';

  /**
   * Constructor
   *
   * @param {AuthService} _authService
   * @param _storageUtilsService
   * @param _cookieService
   // * @param matomoTracker
   * @param matomoTracker
   * @param window
   * @param injector
   */
  constructor(
    private _authService: AuthService,
    private _storageUtilsService: StorageUtilsService,
    private _cookieService: CookieService,
    private matomoTracker: MatomoTracker,
    @Inject(WINDOW) private window: Window,
    private readonly injector: Injector
  ) {
    this.matomoTracker.getVisitorId().then(res => {
      if (res?.length) {
        this.visitorId = res;
        this._storageUtilsService.set('mvId', res)
      }
    });
    if (this._storageUtilsService.check('mvId')) {
      this.visitorId = this._storageUtilsService.get('mvId');
    }
  }

  /**
   * Intercept
   *
   * @param req
   * @param next
   */
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    let headerConfig = {
      'Accept': 'application/json',
    };

    if (this._authService.accessToken) {
      headerConfig = {...headerConfig, ...{'Authorization': 'Bearer ' + this._authService.accessToken}};
    }
    if (this._cookieService.get('__tr_id')) {
      headerConfig = {...headerConfig, ...{'X-PROFILE-ID': this._cookieService.get('__tr_id')}};
    }

    req = req.clone({
      setHeaders: headerConfig
    });
    return next.handle(req).pipe(
      catchError((error) => {

        // Catch "401 Unauthorized" responses
        if (error instanceof HttpErrorResponse && error.status === 401 && !this._authService._isLogin) {
          this._authService.signOut();
          location.reload();
        }
        const err = error.url.includes('checkout/pay') ||
        error.url.includes('checkout/purchase_attribute') ||
        error.url.includes('checkout/purchase_pick') ? error : error.error;
        return throwError(err);
      })
    );
  }
}

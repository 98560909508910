import {NgModule} from '@angular/core';
import {SafeUrlPipe} from "./safe-url.pipe";
import {LocaleDatePipe} from "./locale-date.pipe";
import {AddPlusPipe} from "./add-plus.pipe";

@NgModule({
  declarations: [
    SafeUrlPipe,
    LocaleDatePipe,
    AddPlusPipe
  ],
  exports: [
    SafeUrlPipe,
    LocaleDatePipe,
    AddPlusPipe
  ]
})
export class PipeModule {
}

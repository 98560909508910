import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {BehaviorSubject, Observable} from 'rxjs';
import {map, tap} from 'rxjs/operators';
import {StorageUtilsService} from "@core/utils/storage.utils.service";


@Injectable({
  providedIn: 'root'
})
export class PublicService {

  _posts: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  private _pagination: BehaviorSubject<any | null> = new BehaviorSubject(null);
  /**
   * Constructor
   *
   * @param {HttpClient} _httpClient
   * @param _storageUtilsService
   */
  constructor(
    private _httpClient: HttpClient,
    private _storageUtilsService: StorageUtilsService
  ) {
  }

  get posts(): any[] {
    return this._posts.getValue();
  }

  set posts(val) {
    this._posts.next(val);
  }

  get pagination(): any {
    return this._pagination.getValue();
  }


  getById(id: number | string, params = {}): Observable<any> {
    return this._httpClient.get<any>(`${this._storageUtilsService.urlPublic()}/pages/${id}`, {
      params
    }).pipe(
      map((res) => res.data)
    );
  }

  getPostId(id: number | string, params = {}): Observable<any> {
    return this._httpClient.get<any>(`${this._storageUtilsService.urlPublic()}/posts/${id}`, {
      params
    }).pipe(
      map((res) => res.data)
    );
  }

  contact(data: any): Observable<any> {
    return this._httpClient.post<any>(`${this._storageUtilsService.urlPublic()}/contact`, data);
  }

  sendTokenFirebase(data: any): Observable<any> {
    return this._httpClient.post<any>(`${this._storageUtilsService.urlPublic()}/firebase/subscribe_to_topic `, data);
  }

  sendSubscribe(value: any): Observable<any> {
    return this._httpClient.post<any>(`${this._storageUtilsService.urlPublic()}/subscription`, value);
  }

  loadNotifications(params = {}): Observable<any> {
    return this._httpClient.get<any>(`${this._storageUtilsService.urlWithProfile()}/notifications`, {
      params
    }).pipe(map((res) => res?.data || []));
  }

  makeRead(data: any): Observable<any> {
    return this._httpClient.post<any>(`${this._storageUtilsService.urlWithProfile()}/notifications/make_read`, data);
  }


  loadPayments(params = {}): Observable<any> {
    return this._httpClient.get<any>(`${this._storageUtilsService.urlWithProfile()}/payments`, {
      params
    }).pipe(map((res) => res.data));
  }

  loadOrders(params = {}): Observable<any> {
    return this._httpClient.get<any>(`${this._storageUtilsService.urlWithProfile()}/order_items`, {
      params
    }).pipe(map((res) => res.data));
  }

  loadPosts(params = {}): Observable<any> {
    return this._httpClient.get<any>(`${this._storageUtilsService.urlPublic()}/posts`, {
      params
    }).pipe(
      tap((res) => {
        this._posts.next(this.posts.concat(res.data));
        this.setPagination(res);
      })
    );
  }
  setPagination(data: any): void {
    const params = {
      page: ++data.current_page <= data.last_page ? data.current_page : null,
    };
    this._pagination.next(params);
  }



}

import {AfterViewInit, Component} from '@angular/core';
import {PusherService} from "@core/utils/pusher.service";
import {StorageUtilsService} from "@core/utils/storage.utils.service";
import {AuthService} from "@core/auth/auth.service";
import {FirebaseService} from "@core/utils/firebase.service";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styles: []
})
export class AppComponent implements AfterViewInit {

  constructor(private _pusherService: PusherService,
              private _authService: AuthService,
              private _storageUtilsService: StorageUtilsService,
              private _firebaseService: FirebaseService) {
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.loadGtag();
      this.subscribeChatwootMessage();
      this.checkServiceWorker();
      this._authService.initGoogle();
      this._pusherService.subscribePusher();
    }, 1000)
  }

  loadGtag() {
    this._storageUtilsService.loadJs('https://www.googletagmanager.com/gtag/js?id=G-2JK2L1CGBB', 'gtag', 'dataLayer')
      .then(async _ => {
        const dataLayer = (window as any)['dataLayer'] || []

        dataLayer.push('js', new Date())
        dataLayer.push('config', 'G-2JK2L1CGBB')
      })
  }

  subscribeChatwootMessage() {
    if (window?.addEventListener) {
      window.addEventListener("message", event => {
        if (event?.data && typeof event.data === 'string' && event.data?.includes('appContext') && !event.data.includes('iFrameResizerChild')) {
          const e = JSON.parse(event.data);
          if (e.event && e.event.toLowerCase() === 'appContext'.toLowerCase()) {
            localStorage.setItem('appcontext', event.data);
          }
        }
      })
    }
  }

  checkServiceWorker() {
    if ("serviceWorker" in navigator) {
      this._firebaseService.init();
      window.addEventListener("load", () => {
        // Register the service worker as soon as the app loads
        navigator.serviceWorker
          .register('/firebase-messaging-sw.js', {scope: '/firebase-cloud-messaging-push-scope'})
          .then((registration) => {
            console.log('Service Worker registered with scope:', registration.scope);
          })
          .catch((err) => {
            console.log('Service worker registration failed, error:', err);
          });
      });
    }
  }

}

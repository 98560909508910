<div class="login-content">
  <div class="modal-header justify-content-center">
    <h4 class="title">{{ isLogin ? 'Login by One-Time Code' : 'Verification' }}</h4>
  </div>
  <p class="pb-3">Enter your {{ amountInputs }} digits code that you received on your email.</p>
  <div class="modal-body">
    <div class="form-area">
      <form [formGroup]="form" (submit)="submit()">

        <div class="row">
          <!--          <div class="col-12">-->
          <!--            <div class="single-input">-->
          <!--              <img src="assets/images/icon/message-icon.png"-->
          <!--                   alt="image">-->
          <!--              <input type="text" placeholder="Enter Your Email" formControlName="username">-->
          <!--              <span class="error" *ngIf="form.get('username')?.touched && form.get('username')?.invalid">-->
          <!--                {{ _formUtilsService.getErrorMessage(form, 'username') }}-->
          <!--              </span>-->
          <!--            </div>-->
          <!--          </div>-->

          <div class="fields">
            <div formArrayName="items"
                 *ngFor="let item of items.controls; let i = index; let last = last;">
              <div [formGroup]="getFormGroup(item)">
                <mat-form-field class="field"
                                [ngClass]="{'mr-0': last}">
                  <input matInput
                         type="number"
                         (keyup)="keyUp($event, i)"
                         (ngModelChange)="keyUp($event, i)"
                         [id]="'input' + i"
                         maxlength="1"
                         [formControlName]="'code'">
                </mat-form-field>
              </div>
            </div>
          </div>

          <div class="d-flex justify-content-between">
            <div class="text-white">
              <app-custom-countdown class="small-left" [date]="date" [type]="'onlyTime'">
              </app-custom-countdown>
            </div>
            <div *ngIf="isAfter">
              <span class="opacity-50">If you haven't received the code!</span>
              <a class="text-white ps-1" (click)="resend()">Resend</a>
            </div>
          </div>

          <div class="btn-area">
            <button class="cmn-btn" type="submit"
                    [disabled]="isLoading"
                    [ngClass]="{'is-process': isLoading}">
              <span>Continue</span>
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>

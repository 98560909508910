import {ChangeDetectorRef, Component, Input, OnDestroy, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {FormUtilsService} from "@core/utils/form-utils.service";
import {Subject, takeUntil} from "rxjs";
import {AuthService} from "@core/auth/auth.service";
import {NotificationUtilsService} from "@core/utils/notification-utils.service";

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
})
export class ForgotPasswordComponent implements OnInit, OnDestroy {

  @Input() type: 'reset' | 'login' = 'reset';

  form: FormGroup = new FormGroup<any>({});
  isLoading: boolean = false;
  private _unsubscribeAll = new Subject();

  constructor(private fb: FormBuilder,
              public _formUtilsService: FormUtilsService,
              private cdr: ChangeDetectorRef,
              private _notificationUtilsService: NotificationUtilsService,
              public _authService: AuthService) {


  }

  get isLogin(): boolean {
    return this.type === 'login';
  }

  ngOnInit() {
    this.form = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
    })
  }

  ngOnDestroy() {
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.unsubscribe();
  }

  changeType(e: any) {
    e.stopPropagation();
    e.preventDefault();
    this._authService.authType = 'logIn';
  }

  submit() {
    if (this.form.invalid) {
      this._formUtilsService.validation(this.form);
      return;
    }
    const data = this.form.getRawValue();
    this.form.disable();
    this.isLoading = true;

    const r = this.isLogin ? this._authService.authCodeRequest(data) :
    this._authService.resetPassword(data)
      r.pipe(takeUntil(this._unsubscribeAll))
      .subscribe(_ => {
          this.isLoading = false;
          this._authService.authType = this.isLogin ? 'loginOneTimeCode' : 'verification';
        },
        error => {
          this.isLoading = false;
          this.form.enable();
          const message = this._formUtilsService.prepareServerError(error, this.form, this.cdr);
          this._notificationUtilsService.showActionNotification(message?.message, message?.type);
        });
  }
}

import {ChangeDetectorRef, Component, Input, OnDestroy, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {FormUtilsService} from "@core/utils/form-utils.service";
import {Subject, takeUntil} from "rxjs";
import {AuthService} from "@core/auth/auth.service";
import {NotificationUtilsService} from "@core/utils/notification-utils.service";

@Component({
  selector: 'app-sign-up',
  templateUrl: './sign-up.component.html',
})
export class SignUpComponent implements OnInit, OnDestroy {

  @Input() dialogRef: any = null;

  form: FormGroup = new FormGroup<any>({});
  isLoading: boolean = false;

  private _unsubscribeAll = new Subject();

  constructor(private fb: FormBuilder,
              public _formUtilsService: FormUtilsService,
              private cdr: ChangeDetectorRef,
              private _notificationUtilsService: NotificationUtilsService,
              public _authService: AuthService) {

  }

  ngOnInit() {
    this.form = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
      first_name: ['', [Validators.required]],
      last_name: ['', [Validators.required]],
      terms: ['', [Validators.required]],
      password: ['', [Validators.required, Validators.minLength(6)]],
      password_confirmation: ['', [Validators.required, Validators.minLength(6)]],
    }, {
      validators: [
        this._formUtilsService.mustMatch('password', 'password_confirmation')
      ]
    });

    // if (this._authService.referrerHash?.length) {
    //   this.form.get('referrer_hash')?.setValue(this._authService.referrerHash);
    // }
  }

  ngOnDestroy() {
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.unsubscribe();
  }

  submit() {
    if (this.form.invalid) {
      this._formUtilsService.validation(this.form);
      return;
    }
    const data = this.form.getRawValue();
    this.form.disable();
    this.isLoading = true;

    this._authService.signUp(data)
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(_ => {
          this.isLoading = false;
          this.dialogRef.close();
        },
        error => {
          this.isLoading = false;
          this.form.enable();
          const message = this._formUtilsService.prepareServerError(error, this.form, this.cdr);
          this._notificationUtilsService.showActionNotification(message?.content, message?.type);
        });
  }
}

import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {StorageUtilsService} from "@core/utils/storage.utils.service";
import {Observable} from "rxjs";
import {map} from "rxjs/operators";
import {Pay} from "../product.interface";


@Injectable()
export class PayService {


  intervalCheckoutResult: number = 0;

  /**
   * Constructor
   *
   * @param {HttpClient} _httpClient
   * @param _storageUtilsService
   */
  constructor(
    private _httpClient: HttpClient,
    private _storageUtilsService: StorageUtilsService
  ) {
  }

  pay(data: Pay, params = {}): Observable<any> {
    const u = data.order_item_id?.length ? 'purchase_attribute' :
      data.pick_ids?.length ? 'purchase_pick' : 'pay';
    return this._httpClient.post<any>(`${this._storageUtilsService.urlPublic()}/checkout/${u}`, data, {
      params: params,
      observe: 'response'
    });
  }

  topUp(data: Pay, params = {}): Observable<any> {
    return this._httpClient.post<any>(`${this._storageUtilsService.urlWithProfile()}/checkout/top_up`, data, {
      params: params
    });
  }

  paypalComplete(params = {}): Observable<any> {
    return this._httpClient.get<any>(`${this._storageUtilsService.urlPublic()}/checkout/complete/paypal`, {
      params
    });
  }

  sendSignature(data: { signature: string }, id: string, params = {}): Observable<any> {
    return this._httpClient.post<any>(`${this._storageUtilsService.urlPublic()}/checkout/set_signature/${id}`, data, {
      params
    }).pipe(map((res) => res.data));
  }

  setIntervalCheckoutResult(callback: any, t = 10000) {
    if (this.intervalCheckoutResult) {
      clearInterval(this.intervalCheckoutResult);
    }
    this.intervalCheckoutResult = setInterval(() => {
      callback();
    }, t)
  }

}

import {AfterViewInit, ChangeDetectorRef, Component, Input, OnDestroy, OnInit} from '@angular/core';
import {AbstractControl, FormArray, FormBuilder, FormGroup, Validators} from "@angular/forms";
import {FormUtilsService} from "@core/utils/form-utils.service";
import {Subject, takeUntil} from "rxjs";
import {AuthService} from "@core/auth/auth.service";
import moment from "moment";
import {NotificationUtilsService} from "@core/utils/notification-utils.service";

@Component({
  selector: 'app-verification',
  templateUrl: './verification.component.html',
})
export class VerificationComponent implements OnInit, AfterViewInit, OnDestroy {

  @Input() dialogRef: any = null;

  form: FormGroup = new FormGroup<any>({});
  isLoading: boolean= false;
  private _unsubscribeAll = new Subject();

  date: any = '';
  items: any = new FormArray([]);

  constructor(private fb: FormBuilder,
              public _formUtilsService: FormUtilsService,
              private cdr: ChangeDetectorRef,
              private _notificationUtilsService: NotificationUtilsService,
              public _authService: AuthService) {


  }

  ngOnInit() {
    this.form = this.fb.group({
      items: this.items,
    });
    for (let i = 0; i < 4; i++) {
      const items = this.items as FormArray;
      items.push(this.createItem());
    }
    this.date = moment().add('30', 'seconds').format();
  }

  ngOnDestroy() {
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.unsubscribe();
  }

  ngAfterViewInit() {
    this.focusOneInput();
  }


  createItem(): FormGroup {
    return this.fb.group({
      code: ['', [Validators.required, Validators.maxLength(1)]],
    });
  }

  focusOneInput(): void {
    if (this.items.controls?.length) {
      setTimeout(() => {
        if (document.getElementById('input0')) {
          document.getElementById('input0')?.focus();
        }
      }, 300)
    }
  }

  getFormGroup(control: AbstractControl): FormGroup {
    return control as FormGroup;
  }

  d(e: any, i: any): void {
    const c = this.items.controls[i];
    if (e?.key === 'Backspace') {
      if (i > 0) {
        this.items.controls[i - 1].get('code')?.setValue('');
        document.getElementById(`input${i - 1}`)?.focus();
      }
      return;
    }
    if (e?.currentTarget) {
      return;
    }
    // if(c?.get('code')?.value || c?.get('code')?.value === 0){
      // c.get('code')?.setValue(e, {emitEvent: false});
      // return;
    // }
    if (e || e === 0) {
      if (i < 3) {
        document.getElementById(`input${i + 1}`)?.focus();
      } else {
        this.submit()
      }
    }
  }

  resend() {
    this.isLoading = true;
    this._authService.resetPassword()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(_ => {
          this.isLoading = false;
          this.date = moment().add('30', 'seconds').format();
        },
        error => {
          this.isLoading = false;
          this.form.enable();
          const message = this._formUtilsService.prepareServerError(error, this.form, this.cdr);
          this._notificationUtilsService.showActionNotification(message?.content, message?.type);
        });
  }

  get isAfter() {
    return moment().isAfter(this.date);
  }

  submit() {
    if (this.form.invalid) {
      this._formUtilsService.validation(this.form.get('items'));
      return;
    }
    this.isLoading = true;
    let items: any[] = this.form.get('items')?.value || [];
    let data = {code: ''};
    if (items?.length) {
      let value = items.map(i => i.code).join('');
      if (!value?.length || value?.length < 4) {
        this._formUtilsService.validation(this.form.get('items'));
        return;
      }
      data.code = value;
    }
    this.form.disable();

    this._authService.resetPasswordValidate(data)
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(_ => {
          this.isLoading = false;
          this._authService.authType = 'newPassword';
        },
        error => {
          this.form.enable();
          this.isLoading = false;
          const message = this._formUtilsService.prepareServerError(error, this.form, this.cdr);
          this._notificationUtilsService.showActionNotification(message?.content, message?.type);
        });
  }
}

import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ActionNotificationComponent} from './action-notification.component';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from "@angular/material/input";

@NgModule({
    declarations: [
        ActionNotificationComponent
    ],
    imports: [
        CommonModule,
        MatInputModule,
        MatIconModule
    ],
    exports: [
        ActionNotificationComponent
    ]
})
export class ActionNotificationModule {
}

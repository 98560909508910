<!-- Container -->
<div class="container">

  <!-- Content -->
  <div class="content">

    <div class="ft themesflat-container border-radius-top-0">
      <div class="tf-section green-gradient-bg">
        <div class="content-layout">
          <router-outlet></router-outlet>
        </div>
      </div>
    </div>

  </div>

</div>

import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';

import {ActionNotificationModule} from './components/action-natification/action-notification.module';
import {PipeModule} from "./pipe/pipe.module";
import {MaterialModule} from "./material.module";

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        PipeModule,
        MaterialModule,
    ],
    exports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        ActionNotificationModule,
        PipeModule,
        MaterialModule
    ]
})
export class SharedModule {
}

import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot} from '@angular/router';
import {Observable, of} from 'rxjs';
import {environment} from '@environments/environment';
import {StorageUtilsService} from '@core/utils/storage.utils.service';
import CryptoJS from 'crypto-js';

import {UserService} from './layout/common/user/user.service';
import {AuthService} from '@core/auth/auth.service';
import {MatomoTracker} from 'ngx-matomo';

@Injectable({
  providedIn: 'root'
})
export class InitialDataResolver implements Resolve<any> {

  /**
   * Constructor
   *
   * @param _router
   * @param _userService
   * @param _authService
   // * @param matomoTracker
   * @param matomoTracker
   * @param _storageUtilsService
   */
  constructor(
    private _router: Router,
    private _userService: UserService,
    private _authService: AuthService,
    private matomoTracker: MatomoTracker,
    private _storageUtilsService: StorageUtilsService
  ) {
    // new Image().src = 'assets/images/logo/logo_new.webp';
    // new Image().src = 'assets/images/backgroup-secsion/unsplash.webp';
    // if (this._storageUtilsService.isXsSize) {
    //     new Image().src = 'assets/images/backgroup-secsion/various-bg-mobile.webp';
    //     new Image().src = 'assets/images/backgroup-secsion/ref_cutout_mobile.webp';
    // } else {
    //     new Image().src = 'assets/images/backgroup-secsion/various-sport-bg.webp';
    //     new Image().src = 'assets/images/backgroup-secsion/refcutout1.webp';
    // }
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Private methods
  // -----------------------------------------------------------------------------------------------------

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Resolver
   *
   * @param route
   * @param state
   */
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
    if (this._authService.hasAccessToken) {
      this._userService.loadUser().subscribe();
      setTimeout(() => {
        this.subscribeChatWootEvent();
      })
    }
    if (environment.websiteToken?.length) {
      if (!this._storageUtilsService.isBackend) {
        this._loadSupport(environment.websiteToken);
      }
    }
    return of(true)
  }

  _loadSupport(webSiteToken: string): void {
    (window as any)['websiteToken'] = webSiteToken;

    const supportScript = document.getElementById('web-support');
    if (supportScript) {
      return;
    }

    const body = document.body;
    const script = document.createElement('script');
    script.id = 'web-support';
    script.innerHTML = '';
    script.src = '/assets/js/support.js';
    script.async = true;
    script.defer = true;
    body.appendChild(script);
  }

  subscribeChatWootEvent(): void {
    window.addEventListener('chatwoot:ready', event => {
      if (this._userService.user?.id) {
        return this.setUserForSupport(event)
      }
      this._userService.user$.subscribe(res => {
        if (res?.id?.length) {
          this.setUserForSupport(event);
        }
      });
    }, false);
  }

  private setUserForSupport(event: any): any {
    if (event.target?.$chatwoot
      && this._userService.user
      && environment.web_chat_website_user_identify_hash
    ) {
      const user = this._userService.user;

      const key = CryptoJS.enc.Utf8.parse(environment.web_chat_website_user_identify_hash);
      const email = CryptoJS.enc.Utf8.parse(user.email);
      const hmac = CryptoJS.enc.Hex.stringify(CryptoJS.HmacSHA256(email, key));

      event.target.$chatwoot.setUser(user.email, {
        name: user?.first_name + ' ' + user?.last_name,
        email: user.email,
        identifier_hash: hmac,
      });

      event.target.$chatwoot.setCustomAttributes({
        userId: user.id,
        email: user.email,
        verified: user.email_verified_at,
        userType: user.type,
        joined: user.created_at,
      });
    }
  }
}

import {NgModule} from '@angular/core';
import {CheckScrollDirective} from "./check-scroll.directive";
import {AnimateDirective} from "@shared/directive/animate.directive";


@NgModule({
  declarations: [
    CheckScrollDirective,
    AnimateDirective
  ],
  exports: [
    CheckScrollDirective,
    AnimateDirective
  ]
})
export class DirectiveModule {
}

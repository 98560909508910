import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {CenteredLayoutComponent} from 'app/layout/layouts/horizontal/centered/centered.component';
import {CommonModule} from "@angular/common";
import {FooterModule} from "./footer/footer.module";
import {HeaderComponent} from "./header/header.component";
import {MatIconModule} from "@angular/material/icon";
import {DirectiveModule} from "@shared/directive/directive.module";
import {MatDialogModule} from "@angular/material/dialog";
import {PayService} from "../../../../modules/public/products/pay/pay.service";
import {StripeService} from "../../../../modules/public/products/pay/stripe.service";
import {PaypalService} from "../../../../modules/public/products/pay/paypal.service";
import {FormUtilsService} from "@core/utils/form-utils.service";
import {GravatarModule} from "ngx-gravatar";
import {MenuNotificationModule} from "../../../common/menu-notification/menu-notification.module";

@NgModule({
  declarations: [
    CenteredLayoutComponent,
    HeaderComponent,
  ],
    imports: [
        CommonModule,
        RouterModule,
        FooterModule,
        MatIconModule,
        DirectiveModule,
        MatDialogModule,
        GravatarModule,
        MenuNotificationModule
    ],
  providers: [
    PayService,
    StripeService,
    PaypalService,
    FormUtilsService
  ],
  exports: [
    CenteredLayoutComponent
  ]
})
export class CenteredLayoutModule {
}

import {AfterViewInit, Component, ElementRef, ViewChild} from '@angular/core';
import {COMMON} from "@shared/contstants/common.const";
import {ActivatedRoute, NavigationEnd, Router} from "@angular/router";
import {Location} from '@angular/common';
import {AuthComponent} from "../../../../../modules/auth/auth.component";
import {MatDialog} from "@angular/material/dialog";
import {AuthService} from "@core/auth/auth.service";
import {UserService} from "../../../../common/user/user.service";
import {StorageUtilsService} from "@core/utils/storage.utils.service";

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements AfterViewInit {

  logoPatch = COMMON.LOGO_PATCH;
  @ViewChild('header', {read: ElementRef}) header: ElementRef = new ElementRef<any>({});
  @ViewChild('navbarContent', {read: ElementRef}) navbarContent: ElementRef = new ElementRef<any>({});
  user: any = {}

  currentHref: string = '';

  constructor(router: Router,
              private _route: ActivatedRoute,
              public _storageUtilsService: StorageUtilsService,
              public _authService: AuthService,
              public _userService: UserService,
              private _dialog: MatDialog,
              private location: Location) {
    this.currentHref = this.location.path().split('?')[0];

    router.events.subscribe(event => {
      switch (true) {
        case event instanceof NavigationEnd: {
          this.currentHref = this.location.path().split('?')[0];
          if (this._storageUtilsService.isLg) {
            const n = this.navbarContent.nativeElement;
            if (n.classList.contains('show')) {
              this.toggleMenu();
            }
          }
          this.setActive();
          break;
        }
        default: {
          break;
        }
      }
    });
    this._userService.user$.subscribe(_ => {
      this.user = this._userService.user;
    })
  }

  ngAfterViewInit() {
    this.setActive();
  }

  showModal(type: string) {
    this._dialog.open(AuthComponent, {
      panelClass: 'auth-modal',
      data: {
        type: type
      }
    });
  }

  changeClassForHeader(event: any): void {
    if (event) {
      if (event.scrollTop > 150) {
        this.header.nativeElement.classList.add('animated', 'fadeInDown', 'header-fixed');
      } else {
        this.header.nativeElement.classList.remove('animated', 'fadeInDown', 'header-fixed');
      }
      const scrollArrow = document.getElementById('scroll-arrow');
      if (scrollArrow) {
        if (event.scrollTop > 800) {
          scrollArrow.classList.add('active');
        } else {
          scrollArrow.classList.remove('active');
        }
      }
    }
  }

  toggleMenu() {
    const n = this.navbarContent.nativeElement;
    const overlay: HTMLElement | null = document.getElementById('menu-overlay');
    if (!n.classList.contains('show')) {
      overlay?.classList?.add('show');
      n.classList.remove('collapse');
      n.classList.add('collapsing');
      n.style.height = 0;
      setTimeout(() => {
        n.style.height = ''
        n.classList.remove('collapsing');
        n.classList.add("collapse", "show")
      }, 300)

      setTimeout(() => {
        n.style.height = n.scrollHeight + 'px';
      })
    } else {
      overlay?.classList?.remove('show');
      n.style.height = n.scrollHeight + 'px';
      n.classList.add('collapsing');
      n.classList.remove("collapse", "show")

      setTimeout(() => {
        n.style.height = ''
        n.classList.remove('collapsing');
        n.classList.add("collapse")
      }, 300)

      setTimeout(() => {
        n.style.height = 0;
      })
    }
  }


  setActive(): void {
    const f = this._route.snapshot.queryParams['f'] || null;
    const items: HTMLCollection = this.navbarContent.nativeElement?.firstChild?.children || [];
    Object.values(items).map((item: any) => {
      item.firstChild.classList.remove('active');
      if (!this.currentHref?.length) {
        if ((item.id.includes('h2') && f?.length) || (item.id.includes('home') && !f?.length)) {
          return item.firstChild.classList.add('active');
        }
      }
      if (this.currentHref.replace('/', '') === item.id) {
        item.firstChild.classList.add('active');
      }
    })
  }


}

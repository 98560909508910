import {
  ChangeDetectorRef,
  Component,
  OnInit,
} from '@angular/core';
import {
  ActivatedRoute,
  NavigationEnd,
  Router
} from '@angular/router';
import {StorageUtilsService} from "@core/utils/storage.utils.service";

import {LayoutService} from "../../../layout.service";
import {TracardiService} from "@core/utils/tracardi.service";
import {Title} from "@angular/platform-browser";
import {PayModalComponent} from "../../../../modules/public/products/pay/modal/modal.component";
import {MatDialog} from "@angular/material/dialog";

@Component({
  selector: 'centered-layout',
  templateUrl: './centered.component.html',
  styleUrls: ['./centered.component.scss']
})
export class CenteredLayoutComponent implements OnInit {

  data: any;


  /**
   * Constructor
   *
   * @param {ActivatedRoute} _activatedRoute
   * @param {Router} _router
   * @param _storageUtilsService
   * @param cdr
   * @param dialog
   * @param titleService
   * @param _layoutService
   * @param _tracardiService
   */
  constructor(
    private _activatedRoute: ActivatedRoute,
    private _router: Router,
    private _storageUtilsService: StorageUtilsService,
    private cdr: ChangeDetectorRef,
    private dialog: MatDialog,
    private titleService: Title,
    public _layoutService: LayoutService,
    private _tracardiService: TracardiService,
  ) {
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void {

    // Subscribe to the resolved route data

    this.setRef();
    this._router.events.subscribe((event: any) => {
      switch (true) {
        case event instanceof NavigationEnd: {
          this.setRef();
          setTimeout(() => {
            const title = this.titleService.getTitle();
            this._tracardiService.track('page-view', {
              "url": window.location.href,
              "pageTitle": title || 'BetsBeats'
            })
          })
          break;
        }
        default: {
          break;
        }
      }
    });
  }

  setRef() {
    if (!this._storageUtilsService.get('source')) {
      const source = this._activatedRoute.snapshot.queryParams['s'] ||
        this._activatedRoute.snapshot.queryParams['utm_source'];
      if (source) {
        const dateNow = new Date();
        dateNow.setDate(dateNow.getDate() + 1);
        this._storageUtilsService.set('source', source, dateNow)
      }
    }
    if (!this._storageUtilsService.get('affiliate')) {
      const a = this._activatedRoute.snapshot.queryParams['r'];
      if (a) {
        const dateNow = new Date();
        dateNow.setDate(dateNow.getDate() + 1);
        this._storageUtilsService.set('affiliate', a, dateNow)
      }
    }
    this.cdr.markForCheck();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  scrollTop(): void {
    window.scrollTo({top: 0, behavior: "smooth"}); // how far to scroll on each step
  }

  get isShowBasket(): boolean {
    return !!this._storageUtilsService.get('payProcessData') && !window.location.href.includes('/tips');
  }

  openPayForm() {
    this.dialog.open(PayModalComponent, {
      panelClass: 'panel-class-pay-modal',
      data: this._storageUtilsService.get('payProcessData')
    });
  }

}

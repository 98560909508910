import {
  ChangeDetectionStrategy, ChangeDetectorRef,
  Component,
  OnInit, ViewEncapsulation,
} from '@angular/core';
import {PublicService} from "../../../modules/public/public.service";
import {Router} from "@angular/router";

@Component({
  selector: 'app-menu-notification',
  templateUrl: './menu-notification.component.html',
  styleUrls: ['./menu-notification.component.scss']
})
export class MenuNotificationComponent implements OnInit {

  params = {orderBy: 'created_at', order: 'desc', is_deleted: 0, per_page: 10, page: 0};

  items: any = [];

  /**
   * Constructor
   * @param _publicService
   * @param _router
   */
  constructor(
    private _publicService: PublicService,
    private _router: Router
  ) {
  }

  ngOnInit(): void {
    this.loadNotifications();
  }

  loadNotifications(){
    this._publicService.loadNotifications()
      .subscribe(res => {
        this.items = res;
      })
  }

  readAll(s: HTMLElement, o: HTMLElement){
    this.items = [];
    this.toggle(s, o);
    const ids = this.items.map((i: any) => i.id)
    this._publicService.makeRead({ids}).subscribe();
  }

  get hasUnread(): boolean {
    return this.items?.length && this.items.filter( (i: any) => i.status !== 'read')?.length;
  }

  navigate(type: string, s: HTMLElement, o: HTMLElement){
    if(type === 'packagePurchaseSuccess'){
      this._router.navigateByUrl('/offers');
    }
    this.toggle(s, o);
  }

  toggle(s: HTMLElement, o: HTMLElement) {
    s.classList.toggle('active');
    o.classList.toggle('show');
  }
}

import {Component, OnInit, Inject, ChangeDetectorRef, OnDestroy} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {AuthService} from '@core/auth/auth.service';
import {PayService} from "../pay.service";
import {Method, Pay, PayItemInfo, Product} from "../../product.interface";
import {FormUtilsService} from "@core/utils/form-utils.service";
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {StripeService} from "../stripe.service";
import {ProductsService} from "../../products.service";

import {Router} from "@angular/router";
import {PusherService} from "@core/utils/pusher.service";
import {StorageUtilsService} from "@core/utils/storage.utils.service";

import moment from "moment";
import {Subject} from "rxjs";
import {takeUntil} from "rxjs/operators";
import {PaymentOrderInterface} from "../../success/payment-order.interface";
import {COMMON} from "@shared/contstants/common.const";
import {MatomoService} from "@core/utils/matomo.service";

@Component({
  selector: 'app-pay-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss'],
})
export class PayModalComponent implements OnInit, OnDestroy {

  p: Product = this.data.product;
  payInfo: Pay = Object.assign({payment_method_id: ''}, this.data.pay);
  products: Product[] = this.data.products;
  localData: {
    preventSelectType: string,
    pageType: string,
    buttonConfig: any,
    subTitle?: string,
  } = this.data.localData || {preventSelectType: 'PaymentElement', pageType: '', buttonConfig: null};
  terms: FormControl = new FormControl('', [Validators.required, Validators.requiredTrue]);

  isTopUpBalance = false;

  // alreadyPayedItems: Product[] = [];

  resPay: any = {};

  formConfig: any = {}

  form: FormGroup = new FormGroup<any>({});

  methods: Method[] = this._productsService.methods;
  selectedMethod: Method | null = null;

  coupon: {
    show: boolean,
    group: FormGroup,
    message: any,
    isLoading: boolean
  } = {
    show: false,
    group: new FormGroup<any>({discount: new FormControl('', Validators.required)}),
    message: null,
    isLoading: false
  }

  signaturePad: any = {};

  allAlreadyPayed: boolean = false;

  message: { text: string, status: string } = {
    text: '', status: ''
  }

  // closeFrameWindowEvent = false;
  private _unsubscribeAll = new Subject();

  constructor(private _authService: AuthService,
              private _productsService: ProductsService,
              private _storageUtilsService: StorageUtilsService,
              private _payService: PayService,
              public _formUtilsService: FormUtilsService,
              private _fb: FormBuilder,
              private _stripeService: StripeService,
              private _cdr: ChangeDetectorRef,
              private _pusherService: PusherService,
              private _matomoService: MatomoService,
              private _router: Router,
              public dialogRef: MatDialogRef<PayModalComponent>,
              @Inject(MAT_DIALOG_DATA) public data: {
                product: Product,
                pay: Pay,
                products: Product[],
                localData: { preventSelectType: string, pageType: string, buttonConfig: any }
              }) {
    this._productsService.setMethods();
    this.methods = this._productsService.methods;
  }

  ngOnInit(): void {

    if (this.localData.pageType === 'topUpBalance') {
      this.isTopUpBalance = true;
      // this.p.image = COMMON.LOGO_PATCH;
      this.p.name = 'TOP UP';
      this.methods = this._productsService.methods.filter(m => m.merchant === 'cryptomus');
      this.selectMethod(this.methods[0]);
      return this.goMethods();
    }

    this._matomoService.setEcommerceView(this.p.id, this.p.name, '', Number(this.payInfo.total));
    this.payInfo.items = this.data.pay.items.map(i => {
      return {...i}
    })


    if (this.localData.pageType !== 'manyItems') {
      this.payInfo.items.unshift({
        product_id: this.p.id,
        attributes: this.p.attributes || []
      })
      this.track(this.p);
    }

    if (this.products?.length && this.localData.pageType === 'manyItems') {
      this.products.map(a => {
        this.track(a)
      })
    }

    this.setPayInProcess();


    window.addEventListener('message', this.receiveMessage.bind(this), false);

    // this.setFormConfig('isCryptomus');
    this.checkUserForm();
  }

  setPayInProcess() {
    this._storageUtilsService.set('payProcessData', this.data);
  }

  track(p: any) {
    this._matomoService.addEcommerceItem(p.id, p.name, '', Number(p.total));
    this._matomoService.trackEcommerceCartUpdate(Number(this.payInfo.total));
  }

  ngOnDestroy(): void {
    if (!this.resPay?.data?.id?.length) {
      this._matomoService.clearEcommerceCart();
      this._matomoService.trackEcommerceCartUpdate(0);
    }
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
  }

  setFormConfig(key: string): void {
    Object.keys(this.formConfig).map(k => {
      this.formConfig[k] = false;
    });
    this.formConfig[key] = true;
  }

  checkUserForm() {
    if (this._storageUtilsService.get('user')) {
      this.payInfo.user = this._storageUtilsService.get('user')
    }
    if (this._authService.hasAccessToken || this.payInfo.user?.email?.length) {
      return this.goMethods();
    }

    const m = moment().unix();
    const user = this.payInfo.user || {
      email: 'test@test.com' + m,
      email_confirmation: 'test@test.com' + m,
      first_name: 'Unknown',
      last_name: 'Unknown'
    };

    this.form = this._fb.group({
      user: this._fb.group({
        email: [user.email, [Validators.required, Validators.email]],
        email_confirmation: [user.email_confirmation, [Validators.required, Validators.email]],
        first_name: [user.first_name, [Validators.required]],
        last_name: [user.last_name, [Validators.required]],
      })
    })
    this.goMethods();
  }

  goMethods() {
    this.setMethods();
    this.setFormConfig('isMethod');
  }

  payWithStripePaymentButtons() {
    const m = this._productsService.methods.find((m: Method) => m.merchant === 'stripe');
    if (m?.id) {
      this.selectMethod(m);
      this.localData.preventSelectType = 'stripePaymentButtons';
      this.pay();
    }
  }

  setMethods() {
    if (this.methods?.length && this.methods.findIndex(m => m.icon?.length) > 0) {
      return;
    }
    this.methods.map((m: Method) => {
      if (m.merchant === 'stripe') {
        const publicKey = m.public_data?.public_key || '';
        m.label = 'Creditcard';
        m.asyncGooglePay = this._stripeService.checkPayButton(publicKey, 'google');
        m.asyncApplePay = this._stripeService.checkPayButton(publicKey, 'apple');
        m.icon = 'assets/icons/cards.webp';
      }
    });
    this.selectMethod(this.methods[0]);
  }

  send() {
    if (this.message.status === 'alreadyPurchasedError') {
      if (this.allAlreadyPayed) {
        this.close();
      } else {
        this.localData.preventSelectType = '';
        this.pay();
      }
      return;
    }
    if (this.message.status === 'forceClose' || this.message.status === 'failed') {
      this.close();
      return;
    }

    if (this.formConfig.isStripe) {
      this._stripeService.submit(this.stripeStatus.bind(this), this.setFormConfig.bind(this));
    }
    if (this.formConfig.isMethod) {
      if (!this._authService.hasAccessToken && !this.payInfo.user?.email?.length) {
        if (this.form?.get('user')?.invalid) {
          this._formUtilsService.validation(this.form?.get('user'));
          return;
        }
        this.form.get('user')?.get('email_confirmation')?.setValue(this.form.get('user')?.get('email')?.value);
        this.payInfo.user = this.form?.get('user')?.getRawValue();
      }
      this.localData.preventSelectType = '';
      this.pay()
    }
  }

  stripeStatus(e: any) {
    if (e?.status === 'succeeded') {
      this.goToSuccessPage();
    } else {
      let m = e?.error?.message?.length ? e.error?.message : 'Your payment has been declined. Try again later';
      this.message = {
        status: 'failed',
        text: m
      }
      this.setFormConfig('isStatus');
    }
  }


  goSelectedMethod() {
    if (this.selectedMethod?.merchant === 'stripe') {
      const key = this.resPay?.merchant_data?.client_secret || '';
      const publicKey = this.selectedMethod.public_data?.public_key || '';
      const type = this.localData?.preventSelectType || 'PaymentElement';
      this._stripeService.connectStripe(publicKey, key, type, this.stripeStatus.bind(this), this.setFormConfig.bind(this));
    }
    if (this.selectedMethod?.merchant === 'cryptomus') {
      const url = this.resPay?.merchant_data?.hosted_url || '';
      if (url) {
        this.setFormConfig('isCryptomus');
        this.pushEventSubscribe();
      } else {
        this.message = {
          status: 'failed',
          text: 'At the moment, payment with cryptocurrency does not work. Try changing your payment method'
        }
        this.setFormConfig('isStatus');
      }
    }
  }

  get cryptomusUrl(): string {
    return this.resPay?.merchant_data?.hosted_url || '';
  }

  receiveMessage(event: any): any {
    // if (event.origin === 'https://embeds.payments.dev.zenzenzen.net') {
    let eventData = event.data;
    if (eventData && typeof eventData === 'string') {
      eventData = JSON.parse(eventData);
    }
    if (eventData.type) {
      switch (eventData.type) {
        case 'closeFrameWindow':
          this.setFormConfig('isLoading');
          setTimeout(() => {
            this.getItem(this.resPay?.data?.id)
          }, 30000)
          break;
      }
    }
    // }
  }

  selectMethod(m: Method) {
    this.selectedMethod = m;
    this.payInfo.payment_method_id = m.id;
    // this.pay();
  }

  toggleCoupon() {
    this.coupon.show = !this.coupon.show;
    if (this.coupon.show) {
      this.coupon.isLoading = false;
      this.coupon.message = null;
      this.coupon.group.reset();
    }
  }

  saveCoupon(isToggle: boolean = true) {
    if (!this.coupon.group.valid) {
      this._formUtilsService.validation(this.coupon.group);
      return;
    }
    if (!this._authService.hasAccessToken && !this.payInfo.user?.email?.length) {
      if (this.form?.get('user')?.invalid) {
        this._formUtilsService.validation(this.form?.get('user'));
        return;
      }
    }
    this.coupon.isLoading = true;
    this.coupon.message = null;
    const data = {
      ...this.payInfo, ...{
        payment_method_id: this.methods[0].id,
        tip: 0,
        discount: this.coupon.group.get('discount')?.value || '',
      }
    }
    const request = this.isTopUpBalance ?
      this._payService.topUp(data, {preview: 1}) :
      this._payService.pay(data, {preview: 1})
    request
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((res: any) => {
        this.payInfo.newTotal = res.data?.total || '';
        this.payInfo.discount = data.discount;
        this.coupon.isLoading = false;
        if (isToggle) {
          this.toggleCoupon();
        }
      }, (error: any) => {
        this.coupon.isLoading = false;
        this.coupon.message = this._formUtilsService.prepareServerError(error, this.coupon.group, this._cdr);
      })
  }

  pay() {
    if (this.terms.invalid) {
      return this.terms.markAsTouched();
    }
    this.message = {
      text: '',
      status: ''
    };
    if (this.isTopUpBalance) {
      return this.topUp();
    }
    this.checkMetadata();
    this.setFormConfig('isLoading');

    this._matomoService.trackEvent('Ecommerce', 'Start_Pay_Request', this.payInfo.payment_method_id);
    this._payService.pay(this.payInfo)
      .subscribe((res: any) => {
        if (res?.body) {
          this.resPay = res.body;
        }

        this._matomoService.trackEvent('Ecommerce', 'Success_Pay_Request', this.resPay?.data?.id);
        if (!this._authService.hasAccessToken) {
          this._storageUtilsService.set('user', this.payInfo.user);
        }
        this.sendToken(res);
        this.goSelectedMethod();
        this._pusherService.paymentSubscribe(this.resPay?.data?.id);

      }, (err: any) => {
        this._matomoService.trackEvent('Ecommerce', 'Failed_Pay_Request', this.resPay?.data?.id);
        this.sendToken(err);
        this.preparePayError(err.error);
      })
  }

  topUp() {
    this.message = {
      text: '',
      status: ''
    };
    this.checkMetadata();
    this.setFormConfig('isLoading');
    this._payService.topUp(this.payInfo)
      .subscribe((res: any) => {
        this.resPay = res;
        if (!this._authService.hasAccessToken) {
          this._storageUtilsService.set('user', this.payInfo.user);
        }
        this.goSelectedMethod();
        this._pusherService.paymentSubscribe(this.resPay?.data?.id);
      }, (error: any) => {
        this.preparePayError(error);
      })
  }

  preparePayError(error: any) {
    if (error?.message?.includes('Already purchased') && this.localData.pageType === 'manyItems') {
      this.setFormConfig('isStatus');
      this.showPayedProducts(error?.errors);
      return;
    }
    if (error?.message?.includes('user.email')) {
      this.setFormConfig('isUser');
      this._formUtilsService.prepareServerError(error, this.form, this._cdr);
      return;
    }

    const text = error.message?.length ? error.message : error?.error?.length ? error.error : 'Your payment was declined'

    this.message = {
      status: 'failed',
      text: text
    }
    this.setFormConfig('isStatus');
  }

  showPayedProducts(errors: any) {
    if (!errors) {
      return
    }
    const tempItems = this.payInfo.items.map(i => {
      return {...i}
    })
    const returnErrors: any[] = [];
    Object.keys(errors).map((key: string) => {
      const alreadyPurchasedError = errors[key]?.length && errors[key].findIndex((k: string) => k === 'Already purchased')
      if (alreadyPurchasedError !== -1) {
        const index = Number(key.split('.')[1]);
        const productId: any = tempItems[index]?.product_id;
        if (productId) {
          // message: errors[key][0],
          returnErrors.push({
            message: 'Already purchased',
            id: productId
          })
        }
      }
    });
    if (returnErrors?.length) {
      this.dialogRef.close({returnErrors});
    }
  }


  checkMetadata(): void {
    const affiliate = this._storageUtilsService.get('affiliate');
    if (affiliate) {
      this.payInfo.metadata = {...this.payInfo.metadata, ...{affiliate}}
    }
    const source = this._storageUtilsService.get('source');
    if (source) {
      this.payInfo.metadata = {...this.payInfo.metadata, ...{source}}
    }
  }

  close(): void {
    this.dialogRef.close();
  }

  goToSuccessPage() {
    this.setFormConfig('isLoading');
    let hasMessage = false;
    this._pusherService._paymentEvent
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((e: any) => {
        if (e?.message?.type === 'orderUpdate') {
          hasMessage = true;
          this.actionAfterPay();
        }
      })
    setTimeout(() => {
      if (!hasMessage) {
        this.getItem(this.resPay?.data?.id)
      }
    }, 30000)
  }

  pushEventSubscribe() {
    this._pusherService._paymentEvent
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((e: any) => {
        if (e?.message?.type === 'orderUpdate') {
          this.actionAfterPay();
        }
      })
  }

  getItem(id: string): void {
    this._productsService.getOrderByPaymentId(id)
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((res: PaymentOrderInterface) => {
        if (res?.status === 'paid') {
          this.actionAfterPay();
        }
      });
  }

  sendToken(res: any): void {
    if (res && res.headers) {
      const token = res.headers.get('x-authorization');
      if (token) {
        this.addPushToken(token);
      }
    }
  }

  addPushToken(token: string): void {
    if (token.includes('Bearer')) {
      token = token.replace('Bearer ', '');
    }
    this._authService.setToken(token);
  }


  actionAfterPay() {
    this._matomoService.trackEcommerceOrder(this.resPay.data?.id, Number(this.payInfo.total));
    if (this.isTopUpBalance) {
      this.setFormConfig('isStatus');
      this.message = {
        status: 'success',
        text: 'The balance has been successfully replenished'
      }
      return;
    }

    // payment is renew
    if (this.payInfo.order_item_id?.length) {
      this.dialogRef.close({successRenew: true});
      return;
    }

    this.close();
    this._storageUtilsService.remove('payProcessData');
    let url = '/details/success/' + this.resPay?.data?.id;
    if (this.payInfo.pick_ids?.length) {
      url += '?pick=1';
    }
    this._router.navigateByUrl(url);
  }
}

import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {BehaviorSubject, Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {StorageUtilsService} from "@core/utils/storage.utils.service";
import {Method, Product} from "./product.interface";


import moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class ProductsService {

  private _products: BehaviorSubject<Product[]> = new BehaviorSubject<Product[]>([]);
  private _methods: BehaviorSubject<Method[]> = new BehaviorSubject<Method[]>([]);

  isJoinedTelegram: boolean | null = null;
  joinTelegramLink: string = '';


  /**
   * Constructor
   *
   * @param {HttpClient} _httpClient
   * @param _storageUtilsService
   */
  constructor(
    private _httpClient: HttpClient,
    private _storageUtilsService: StorageUtilsService
  ) {
  }

  get methods(): Method[] {
    return this._methods.getValue();
  }

  set methods(value) {
    this._methods.next(value);
  }

  get products(): Product[] {
    return this._products.getValue();
  }

  get products$(): Observable<Product[]> {
    return this._products.asObservable();
  }

  set products(value) {
    this._products.next(value);
  }

  getById(id: number | string, params = {}): Observable<any> {
    return this._httpClient.get<any>(`${this._storageUtilsService.urlPublic()}/pages/${id}`, {
      params
    }).pipe(
      map((res) => res.data)
    );
  }

  getPublicProducts(params = {}): Observable<any> {
    return this._httpClient.get<any>(`${this._storageUtilsService.urlPublic()}/products`, {
      params
    }).pipe(map(res => {
      const products = res.data?.length ? res.data.map((p: any) => {
          p.disabled = moment().isAfter(p.disable_at);
          return p;
        }) : []
      this._products.next(products);
    }));
  }

  getOrderByPaymentId(id: number | string, params = {}): Observable<any> {
    return this._httpClient.get<any>(`${this._storageUtilsService.urlPublic()}/orders/by_payment/${id}`, {
      params
    }).pipe(
      map((res) => res.data)
    );
  }

  autoExtend(id: any, data: any, params?: any): Observable<any> {
    const url = `${this._storageUtilsService.urlWithProfile()}/order_items/${id}/auto_extend`
    return this._httpClient.post<any>(url, data, {params});
  }
  setMethods(params = {}) {
    this.methods = [
      {
        "id": "pm_stripe",
        "name": "Stripe",
        "label": "Stripe",
        "merchant": "stripe",
        "public_data": {
          "public_key": "pk_test_OXnzj4X6VXH1h1NFesABtW9n"
        },
      },
      {
        "id": "pm_cryptomus",
        "name": "Cryptomus",
        "label": "Brakus Group",
        "merchant": "cryptomus",
        "public_data": null,
      },
    ]
    // if (this.methods?.length) {
    //   return of(this.methods);
    // }
    // return this._httpClient.get<any>(`${this._storageUtilsService.urlPublic()}/payment_methods`, {
    //   params
    // }).pipe(
    //   map((res) => {
    //     this.methods = res.data.reverse();
    //     return this.methods;
    //   })
    // );
  }

  getFormDescription(text: string | undefined): any {
    if (text && text.length) {
      return text.replace(/\n/g, '<br/>');
    }
    return '';
  }

}

import {EventEmitter, Injectable} from '@angular/core';
import { Observable} from "rxjs";
import {Location} from '@angular/common';

@Injectable()
export class LayoutService {

  _navSidebarClass: boolean = false;

  constructor(private location: Location) {
  }

  closeSidebar() {
    this._navSidebarClass = false;
  }

  toggleSidebarClass() {
    this._navSidebarClass = !this.navSidebarClass;
  }

  get navSidebarClass(): boolean {
    return this._navSidebarClass;
  }

  get isXs(): boolean {
    const width = document?.documentElement?.clientWidth || window.screen.width;
    return width < 799;
  }

}



<div class="login-content">
  <div class="modal-header justify-content-center">
    <h4 class="title">{{ isLogin ? 'Login by One-Time Code' : 'Forgot Password' }}</h4>
  </div>
  <p class="pb-3">Enter your email for the verification process, we will send 6 digits code to your email.</p>
  <div class="modal-body">
    <div class="form-area">
      <form [formGroup]="form" (submit)="submit()">
        <div class="row">
          <div class="col-12">
            <div class="single-input">
              <img src="assets/images/icon/message-icon.png"
                   alt="image">
              <input type="text" placeholder="Enter Your Email" formControlName="email">
              <span class="error" *ngIf="form.get('email')?.touched && form.get('email')?.invalid">
                {{ _formUtilsService.getErrorMessage(form, 'email') }}
              </span>
            </div>
          </div>
          <div class="col-12">
            <div class="forget d-flex align-items-center justify-content-between">
              <a href="javascript:void(0)"  (click)="changeType($event)">Back to Log in</a>
            </div>
          </div>
          <div class="btn-area">
            <button class="cmn-btn" type="submit"
                    [disabled]="isLoading"
                    [ngClass]="{'is-process': isLoading}">
              <span>Send</span>
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>

import {Injectable} from '@angular/core';
import {environment} from "@environments/environment";
import {StorageUtilsService} from "@core/utils/storage.utils.service";

environment

@Injectable()
export class StripeService {

  clientSecret: string = '';

  stripe: any = {};
  elements: any = {};

  constructor(
    private _storageUtilsService: StorageUtilsService,) {
  }

  connectStripe(publicKey: string, clientSecret: string, type: string, callback: (e: any) => void, callbackFormState: (e: any) => void): void {
    this.clientSecret = clientSecret;
    this._storageUtilsService.loadJs('https://js.stripe.com/v3/', 'stripe-script', 'Stripe')
      .then(async _ => {
        const fn = (window as any)['Stripe'];
        if (!fn || typeof fn !== 'function') {
          return;
        }
        this.stripe = await fn(publicKey, {
          betas: ['elements_customers_beta_1'],
          apiVersion: '2020-08-27',
        })

        let options = {clientSecret: this.clientSecret};
        this.elements = this.stripe.elements(options)
        if (type === 'PaymentElement') {
          this.createPaymentElement();
        }
        const expressCheckoutElement = this.elements.create('expressCheckout', options)
        expressCheckoutElement.mount('#express-checkout-element');
        expressCheckoutElement.on('confirm', (e: any) => {
          this.submit(callback, callbackFormState);
        })
        expressCheckoutElement.on('cancel', (e: any) => {
          callbackFormState('isStripe')
        })

        callbackFormState('isStripe');
      });
  }

  checkPayButton(publicKey: string, type: string): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this._storageUtilsService.loadJs('https://js.stripe.com/v3/', 'stripe-script', 'Stripe')
        .then(async _ => {
          const fn = (window as any)['Stripe'];
          if (!fn || typeof fn !== 'function') {
            return;
          }
          const stripe = await fn(publicKey, {
            betas: ['elements_customers_beta_1'],
            apiVersion: '2020-08-27',
          })

          let paymentRequest = stripe.paymentRequest({
            country: 'US',
            currency: 'usd',
            total: {
              label: '1',
              amount: 1,
            },
          });
          paymentRequest.canMakePayment().then((result: any) => {
            return type === 'google' ? resolve(result?.googlePay) :
              type === 'apple' ? resolve(result?.applePay) :
                resolve(true)
          });
        });
    });
  }

  createPaymentElement() {
    // Create and mount the Payment Element
    const paymentElement = this.elements.create('payment', {
      business: {
        name: "BetsBeats"
      },
      fields: {
        "billingDetails": {
          "address": {
            "line1": "auto"
          }
        }
      },
      layout: {
        type: 'accordion',
        defaultCollapsed: false,
        radios: true,
        spacedAccordionItems: false
      }
    });

    // paymentElement.on('change', (event: any) => {
    //   console.log(event);
    // });

    paymentElement.mount('#payment-element');
  }

  async submit(callback: (e: any) => void, callbackFormState: (e: any) => void) {
    const {error: submitError} = await this.elements.submit();
    if (submitError) {
      return;
    }
    callbackFormState('isLoading');
    this.stripe.confirmPayment({
      elements: this.elements,
      clientSecret: this.clientSecret,
      confirmParams: {
        return_url: 'https://example.com/order/123/complete',
      },
      redirect: "if_required"
    }).then((p: any) => {
      callback(p?.paymentIntent);
    });
  }

}

import {Injectable} from '@angular/core';
import {StorageUtilsService} from "@core/utils/storage.utils.service";

@Injectable()
export class PaypalService {

  constructor(private _storageUtilsService: StorageUtilsService) {
  }

  paypal(clientId: string, orderId: string, autoPay: boolean = false, callback: () => void) {
    this._storageUtilsService.loadJs(`https://www.paypal.com/sdk/js?client-id=${clientId}&currency=USD`, 'paypal-script', 'paypal')
      .then(_ => {
        const paypal = (window as any)['paypal'];
        setTimeout(() => {
          paypal.Buttons({
            style: {
              color: 'blue'
            },
            createOrder: () => orderId,
            onApprove: (e: any) => {
              console.log(e);
              return callback();
            }
          }).render('#paypal-button-container');
        }, 100)
      });
  }

}
